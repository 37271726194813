import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Paper,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import ListeningTestGenerator from '../../components/admin/ListeningTestGenerator';
import UserManagement from '../../components/admin/UserManagement';

export default function AdminDashboard() {
  const navigate = useNavigate();
  const { isAdmin, loading } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [error, setError] = useState(null);

  // Show loading state while checking auth
  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  // Redirect if not admin
  if (!isAdmin) {
    navigate('/');
    return null;
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setError(null); // Clear any errors when switching tabs
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 6 }}>
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 'bold',
            background: 'linear-gradient(45deg, #4051B5 30%, #7E57C2 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            mb: 4,
          }}
        >
          Admin Dashboard
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Paper sx={{ mt: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange}
              aria-label="admin dashboard tabs"
              sx={{
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 500,
                },
                '& .Mui-selected': {
                  color: '#4051B5',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#4051B5',
                },
              }}
            >
              <Tab label="Users & Subscriptions" />
              <Tab label="Listening Tests" />
            </Tabs>
          </Box>

          <Box sx={{ p: { xs: 2, sm: 3 } }}>
            {activeTab === 0 && <UserManagement />}
            {activeTab === 1 && <ListeningTestGenerator />}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
