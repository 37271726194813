import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, AppBar, Toolbar, IconButton, Button, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EditIcon from '@mui/icons-material/Edit';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Logo from './Logo';
import Footer from './Footer';
import defaultTheme from '../theme';
import { useAuth } from '../hooks/useAuth';
import { handleLogout } from '../utils/auth';
import { useDispatch } from 'react-redux';

const drawerWidth = 250;
const miniDrawerWidth = 72;

function Layout({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, isAuthenticated, isAdmin } = useAuth();
  const isPracticePage = location.pathname.startsWith('/practice/');
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Define menu items
  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/' },
    ...(isAuthenticated ? [
      { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
      { text: 'Listening Practice', icon: <HeadphonesIcon />, path: '/practice/listening' },
      { text: 'Reading Practice', icon: <MenuBookIcon />, path: '/practice/reading' },
      { text: 'Writing Practice', icon: <EditIcon />, path: '/practice/writing' },
      { text: 'Speaking Practice', icon: <RecordVoiceOverIcon />, path: '/practice/speaking' },
      ...(isAdmin ? [{ text: 'Admin', icon: <AdminPanelSettingsIcon />, path: '/admin' }] : []),
    ] : []),
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/login') {
      window.location.href = '/login';
    }
  };

  const handleLogoutClick = async (e) => {
    e.preventDefault();
    try {
      await handleLogout()(dispatch);
      window.location.href = '/';
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const drawer = (
    <>
      <Toolbar />
      <List>
        {menuItems.map((item) => {
          const isSelected = location.pathname === item.path;
          return (
            <ListItem 
              key={item.text} 
              component={Link} 
              to={item.path}
              selected={isSelected}
              onClick={() => setDrawerOpen(false)}
              sx={{
                minHeight: { xs: 56, sm: 48 },
                px: 2.5,
                py: { xs: 2, sm: 1.5 },
                mb: { xs: 1.5, sm: 0.5 },
                '&.Mui-selected': {
                  backgroundColor: 'rgba(64, 81, 181, 0.08)',
                  '&:hover': {
                    backgroundColor: 'rgba(64, 81, 181, 0.12)',
                  },
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                borderRadius: '8px',
                mx: 1,
                width: 'auto',
              }}
            >
              <ListItemIcon sx={{ 
                color: isSelected ? '#4051B5' : '#666',
                minWidth: 40,
                '& .MuiSvgIcon-root': {
                  fontSize: '1.5rem',
                }
              }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.text}
                sx={{ 
                  opacity: drawerOpen ? 1 : 0,
                  transition: 'opacity 0.2s ease-in-out',
                  color: isSelected ? '#4051B5' : 'rgba(0, 0, 0, 0.87)',
                  '& .MuiTypography-root': {
                    fontWeight: isSelected ? 600 : 400,
                  },
                  whiteSpace: 'nowrap',
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );

  const authButtonSx = {
    textTransform: 'none',
    fontWeight: 600,
    backgroundColor: 'white',
    color: '#4051B5',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    px: { xs: 2, sm: 3 },
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        {/* AppBar */}
        <AppBar 
          position="fixed" 
          elevation={0}
          sx={{ 
            backgroundColor: '#4051B5',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                color="inherit"
                aria-label="open menu"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ 
                  mr: 2, 
                  color: 'white',
                  display: { xs: 'none', sm: 'block' }
                }}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open mobile menu"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ 
                  mr: 2, 
                  color: 'white',
                  display: { xs: 'block', sm: 'none' }
                }}
              >
                <MenuIcon />
              </IconButton>
              <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                <Logo variant="full" />
              </Link>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {isAuthenticated && (
                <IconButton
                  component={Link}
                  to="/profile"
                  sx={{ 
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                    padding: 1,
                    '& .MuiSvgIcon-root': {
                      fontSize: '2rem'
                    }
                  }}
                  aria-label="Profile"
                >
                  <AccountCircleIcon />
                </IconButton>
              )}
              {isAuthenticated ? (
                <Button 
                  onClick={handleLogoutClick}
                  variant="contained"
                  sx={authButtonSx}
                >
                  Logout
                </Button>
              ) : (
                location.pathname !== '/login' && (
                  <Button 
                    onClick={handleLoginClick}
                    variant="contained"
                    sx={authButtonSx}
                  >
                    Login
                  </Button>
                )
              )}
            </Box>
          </Toolbar>
        </AppBar>

        {/* Mobile drawer */}
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              backgroundColor: '#f8f9fa',
              transition: (theme) => theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* Desktop drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              backgroundColor: '#f8f9fa',
              width: drawerOpen ? drawerWidth : miniDrawerWidth,
              transition: (theme) => theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
              overflowX: 'hidden',
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            ml: { xs: 0, sm: `${drawerOpen ? drawerWidth : miniDrawerWidth}px` },
            transition: (theme) => theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <Toolbar />
          <Box sx={{ flex: 1 }}>
            {children}
          </Box>
          {!isPracticePage && <Footer />}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
