import { Box, LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function ProgressBar({ questions, answers, onQuestionSelect }) {
  const totalQuestions = questions.length;
  const answeredQuestions = Object.keys(answers).length;
  const progress = Math.min((answeredQuestions / totalQuestions) * 100, 100);

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="body2" color="text.secondary">
          Progress
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {answeredQuestions}/{totalQuestions} Questions Answered
        </Typography>
      </Box>
      <LinearProgress 
        variant="determinate" 
        value={progress}
        sx={{
          height: 8,
          borderRadius: 4,
          backgroundColor: 'action.hover',
          '& .MuiLinearProgress-bar': {
            borderRadius: 4,
            backgroundColor: progress === 100 ? 'success.main' : 'primary.main',
          },
        }}
      />
      <Box 
        sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: 1, 
          mt: 2 
        }}
      >
        {questions.map((question, index) => (
          <Box
            key={question.id}
            onClick={() => onQuestionSelect(index)}
            sx={{
              width: 30,
              height: 30,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              cursor: 'pointer',
              bgcolor: answers[question.id] ? 'primary.main' : 'action.hover',
              color: answers[question.id] ? 'primary.contrastText' : 'text.primary',
              '&:hover': {
                bgcolor: answers[question.id] ? 'primary.dark' : 'action.selected',
              },
              transition: 'background-color 0.2s',
            }}
          >
            <Typography variant="body2">
              {index + 1}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

ProgressBar.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  answers: PropTypes.object.isRequired,
  onQuestionSelect: PropTypes.func.isRequired,
};

export default ProgressBar;
