import { useState } from 'react';
import { 
  Paper, 
  Typography, 
  Box, 
  TextField, 
  Button, 
  List, 
  ListItem, 
  ListItemText,
  CircularProgress,
  Divider 
} from '@mui/material';
import PropTypes from 'prop-types';
import { useGeminiChat } from '../../services/geminiService';

function DiscussionChatbot({ feedback }) {
  const [messages, setMessages] = useState([
    {
      role: 'assistant',
      content: `I've analyzed your reading practice performance. Your overall score was ${feedback.score}. 
                Would you like to discuss specific areas for improvement or have questions about any particular section?`
    }
  ]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { generateResponse } = useGeminiChat();

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const userMessage = {
      role: 'user',
      content: newMessage
    };

    setMessages(prev => [...prev, userMessage]);
    setNewMessage('');
    setIsLoading(true);

    try {
      const context = `User's reading practice performance:
        Overall Score: ${feedback.score}
        Detailed Feedback: ${JSON.stringify(feedback.detailedFeedback)}
        General Feedback: ${feedback.feedback}`;

      const response = await generateResponse(newMessage, context);

      const assistantMessage = {
        role: 'assistant',
        content: response
      };

      setMessages(prev => [...prev, assistantMessage]);
    } catch (error) {
      console.error('Error generating response:', error);
      const errorMessage = {
        role: 'assistant',
        content: 'I apologize, but I encountered an error. Please try asking your question again.'
      };
      setMessages(prev => [...prev, errorMessage]);
    }

    setIsLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Paper sx={{ p: 3, mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Discussion Assistant
      </Typography>
      <Typography variant="body2" color="text.secondary" paragraph>
        Ask questions about your performance or get tips for improvement.
      </Typography>

      <List sx={{ maxHeight: 400, overflow: 'auto', mb: 2 }}>
        {messages.map((message, index) => (
          <ListItem
            key={index}
            sx={{
              justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
              mb: 1
            }}
          >
            <Paper
              elevation={1}
              sx={{
                p: 2,
                maxWidth: '80%',
                bgcolor: message.role === 'user' ? 'primary.light' : 'background.paper',
                color: message.role === 'user' ? 'primary.contrastText' : 'text.primary'
              }}
            >
              <ListItemText 
                primary={message.content}
                sx={{ 
                  '& .MuiListItemText-primary': {
                    whiteSpace: 'pre-wrap'
                  }
                }}
              />
            </Paper>
          </ListItem>
        ))}
      </List>

      <Divider sx={{ mb: 2 }} />

      <Box sx={{ display: 'flex', gap: 1 }}>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your question here..."
          disabled={isLoading}
        />
        <Button
          variant="contained"
          onClick={handleSendMessage}
          disabled={!newMessage.trim() || isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Send'}
        </Button>
      </Box>
    </Paper>
  );
}

DiscussionChatbot.propTypes = {
  feedback: PropTypes.shape({
    score: PropTypes.number.isRequired,
    feedback: PropTypes.string.isRequired,
    detailedFeedback: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
        comments: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default DiscussionChatbot;
