import {
  AccessTime as AccessTimeIcon,
  Autorenew as AutorenewIcon,
  AllInclusive as AllInclusiveIcon,
} from '@mui/icons-material';

export const subscriptionPlans = {
  trial: {
    title: '7-Day Free Trial',
    price: '0',
    duration: '7 days',
    icon: AccessTimeIcon,
    features: [
      'Full access to all features',
      'No credit card required',
      'Cancel anytime',
    ],
  },
  monthly: {
    title: 'Monthly Plan',
    price: '19.95',
    duration: 'per month',
    icon: AutorenewIcon,
    features: [
      'Unlimited access to all practice tests',
      'AI-powered writing feedback',
      'Speech recognition for speaking practice',
      'Detailed performance analytics',
      'Progress tracking',
      'Cancel anytime',
    ],
  },
  lifetime: {
    title: 'Lifetime Access',
    price: '249',
    duration: 'one-time payment',
    icon: AllInclusiveIcon,
    recommended: true,
    features: [
      'Everything in Monthly Plan',
      'Never pay again',
      'Priority support',
      'Early access to new features',
      'Downloadable study materials',
      'Personal study plan',
    ],
    savings: 'Save over 80% compared to monthly plan',
  },
};

export const benefits = [
  {
    title: 'Instant Access',
    description: 'Start practicing immediately after signing up',
  },
  {
    title: 'AI-Powered Feedback',
    description: 'Get personalized feedback on your performance',
  },
  {
    title: 'Priority Support',
    description: '24/7 support for any questions or concerns',
  },
  {
    title: 'Regular Updates',
    description: 'Access to new practice materials and features',
  },
];
