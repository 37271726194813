import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  Divider,
  useTheme,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { setError as setAuthError } from '../store/slices/auth';
import { handleAuthError } from '../utils/auth';
import { useAuth } from '../hooks/useAuth';
import logger from '../utils/logger';

const log = logger.component('Login');

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function Login() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading: authLoading, error: authError, isAuthenticated } = useAuth();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [localError, setLocalError] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [touched, setTouched] = useState({ email: false, password: false });

  // Redirect if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      const from = location.state?.from || '/dashboard';
      log.info('User already authenticated, redirecting to:', from);
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, location]);

  // Clear errors when component unmounts or location changes
  useEffect(() => {
    log.debug('Component mounted or location changed');
    return () => {
      log.debug('Clearing errors on unmount/location change');
      dispatch(setAuthError(null));
      setLocalError('');
      setEmailError('');
      setPasswordError('');
      setTouched({ email: false, password: false });
    };
  }, [dispatch, location]);

  const validateEmail = (email) => {
    if (!email) {
      return 'Email is required';
    }
    if (!EMAIL_REGEX.test(email)) {
      return 'Please enter a valid email address';
    }
    return '';
  };

  const validatePassword = (password) => {
    if (!password) {
      return 'Password is required';
    }
    if (password.length < 6) {
      return 'Password must be at least 6 characters long';
    }
    return '';
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    log.debug('Email changed:', { email: newEmail });
    setEmail(newEmail);
    if (touched.email) {
      setEmailError(validateEmail(newEmail));
    }
    setLocalError('');
    dispatch(setAuthError(null));
  };

  const handleEmailBlur = () => {
    setTouched(prev => ({ ...prev, email: true }));
    setEmailError(validateEmail(email));
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    log.debug('Password changed:', { hasPassword: !!newPassword });
    setPassword(newPassword);
    if (touched.password) {
      setPasswordError(validatePassword(newPassword));
    }
    setLocalError('');
    dispatch(setAuthError(null));
  };

  const handlePasswordBlur = () => {
    setTouched(prev => ({ ...prev, password: true }));
    setPasswordError(validatePassword(password));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    log.info('Form submitted', { email });

    if (loading || authLoading) {
      log.debug('Submit blocked - already loading');
      return;
    }

    // Mark all fields as touched
    setTouched({ email: true, password: true });

    // Validate both fields
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);

    setEmailError(emailError);
    setPasswordError(passwordError);

    if (emailError || passwordError) {
      return;
    }

    log.info('Starting login process');
    setLocalError('');
    dispatch(setAuthError(null));
    setLoading(true);

    try {
      log.info('Attempting login', { email });
      await signInWithEmailAndPassword(auth, email, password);
      
      // Navigation will be handled by the useAuth hook when isAuthenticated changes
      log.info('Login successful');
    } catch (err) {
      log.error('Login error', {
        code: err.code,
        message: err.message,
        email,
        stack: err.stack
      });

      // Handle auth error and get error message
      const errorMessage = handleAuthError(err)(dispatch);
      log.debug('Error message from handler:', errorMessage);
      
      // Set local error state for the form
      let localErrorMessage;
      switch (err.code) {
        case 'auth/user-not-found':
          localErrorMessage = 'No account found with this email address';
          break;
        case 'auth/wrong-password':
        case 'auth/invalid-login-credentials':
          localErrorMessage = 'Incorrect password';
          break;
        case 'auth/invalid-email':
          setEmailError('Invalid email format');
          localErrorMessage = 'Invalid email format';
          break;
        case 'auth/too-many-requests':
          localErrorMessage = 'Too many failed attempts. Please try again later.';
          break;
        case 'auth/network-request-failed':
          localErrorMessage = 'Network error. Please check your connection.';
          break;
        default:
          localErrorMessage = 'An error occurred during login. Please try again.';
          log.error('Unhandled error code:', err.code);
      }
      setLocalError(localErrorMessage);
    } finally {
      log.info('Login process completed');
      setLoading(false);
    }
  };

  const isLoading = loading || authLoading;
  const errorMessage = localError || authError;

  return (
    <Container maxWidth="sm" sx={{ mt: { xs: 4, sm: 8 }, mb: 4 }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: { xs: 3, sm: 4 },
          borderRadius: 2,
        }}
      >
        <Typography 
          variant="h4" 
          align="center" 
          gutterBottom
          sx={{
            background: theme.palette.gradient.primary,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 'bold',
            mb: 3,
          }}
        >
          Welcome Back
        </Typography>

        {errorMessage && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              '& .MuiAlert-message': {
                width: '100%'
              }
            }}
          >
            {errorMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmit} noValidate>
          <TextField
            label="Email Address"
            type="email"
            required
            fullWidth
            value={email}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            sx={{ mb: emailError ? 1 : 2 }}
            autoComplete="email"
            error={touched.email && !!emailError}
            disabled={isLoading}
            inputProps={{
              'data-testid': 'email-input'
            }}
          />
          {touched.email && emailError && (
            <FormHelperText 
              error 
              sx={{ 
                ml: 2, 
                mb: 2,
                fontSize: '0.75rem',
                fontWeight: 400,
              }}
            >
              {emailError}
            </FormHelperText>
          )}

          <TextField
            label="Password"
            type="password"
            required
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            onBlur={handlePasswordBlur}
            sx={{ mb: passwordError ? 1 : 3 }}
            autoComplete="current-password"
            error={touched.password && !!passwordError}
            disabled={isLoading}
            inputProps={{
              'data-testid': 'password-input'
            }}
          />
          {touched.password && passwordError && (
            <FormHelperText 
              error 
              sx={{ 
                ml: 2, 
                mb: 3,
                fontSize: '0.75rem',
                fontWeight: 400,
              }}
            >
              {passwordError}
            </FormHelperText>
          )}

          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            disabled={isLoading || (touched.email && !!emailError) || (touched.password && !!passwordError)}
            sx={{
              py: 1.5,
              background: theme.palette.gradient.primary,
              '&:hover': {
                background: theme.palette.gradient.primary,
                opacity: 0.9,
              },
            }}
            data-testid="login-button"
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Sign In'
            )}
          </Button>

          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Link 
              to="/reset-password"
              style={{ 
                color: theme.palette.primary.main,
                textDecoration: 'none',
              }}
            >
              Forgot Password?
            </Link>
          </Box>

          <Divider sx={{ my: 3 }}>OR</Divider>

          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              Don't have an account?{' '}
              <Link 
                to="/signup"
                style={{ 
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  fontWeight: 500,
                }}
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
        </form>
      </Paper>
    </Container>
  );
}

export default Login;
