import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const initialState = {
  currentTest: null,
  answers: {},
  currentSection: 0,
  currentQuestion: 0,
  timeRemaining: null,
  loading: false,
  error: null,
  audioDetails: {
    duration: 0,
    currentTime: 0,
    isPlaying: false,
  },
  audioProgress: 0,
  currentSession: null,
  currentAudio: null,
  transcript: null,
};

export const startNewPracticeSession = createAsyncThunk(
  'listeningPractice/startNewSession',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const session = {
        id: Date.now().toString(),
        startTime: new Date().toISOString(),
        status: 'active'
      };
      dispatch(setCurrentSession(session));
      return session;
    } catch (error) {
      dispatch(setError(error.message));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateAnswer = createAsyncThunk(
  'listeningPractice/updateAnswer',
  async ({ questionId, answer }, { dispatch, getState }) => {
    dispatch(setLoading(true));
    try {
      const { currentSession } = getState().listeningPractice;
      if (!currentSession) throw new Error('No active session');

      await updateDoc(doc(db, 'practice-sessions', currentSession.id), {
        [`answers.${questionId}`]: answer,
        updatedAt: new Date().toISOString()
      });

      dispatch(setAnswer({ questionId, answer }));
      return { questionId, answer };
    } catch (error) {
      dispatch(setError(error.message));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const submitPracticeSession = createAsyncThunk(
  'listeningPractice/submitSession',
  async (_, { dispatch, getState }) => {
    dispatch(setLoading(true));
    try {
      const { currentSession, answers } = getState().listeningPractice;
      if (!currentSession) throw new Error('No active session');

      const completedSession = {
        ...currentSession,
        answers,
        status: 'completed',
        completedAt: new Date().toISOString()
      };

      await setDoc(doc(db, 'practice-sessions', currentSession.id), completedSession);
      dispatch(setCurrentSession(completedSession));
      return completedSession;
    } catch (error) {
      dispatch(setError(error.message));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const listeningPracticeSlice = createSlice({
  name: 'listeningPractice',
  initialState,
  reducers: {
    setCurrentTest: (state, action) => {
      state.currentTest = action.payload;
    },
    setAnswer: (state, action) => {
      const { questionId, answer } = action.payload;
      state.answers[questionId] = answer;
    },
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },
    setTimeRemaining: (state, action) => {
      state.timeRemaining = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = null;
    },
    setAudioDetails: (state, action) => {
      state.audioDetails = {
        ...state.audioDetails,
        ...action.payload,
      };
    },
    updateAudioProgress: (state, action) => {
      state.audioProgress = action.payload;
    },
    setCurrentSession: (state, action) => {
      state.currentSession = action.payload;
    },
    setCurrentAudio: (state, action) => {
      state.currentAudio = action.payload;
    },
    setTranscript: (state, action) => {
      state.transcript = action.payload;
    },
    resetPractice: () => initialState,
  },
});

// Selectors
export const selectCurrentSession = (state) => state.listeningPractice.currentSession;
export const selectCurrentAudio = (state) => state.listeningPractice.currentAudio;
export const selectCurrentTest = (state) => state.listeningPractice.currentTest;
export const selectAnswers = (state) => state.listeningPractice.answers;
export const selectCurrentSection = (state) => state.listeningPractice.currentSection;
export const selectCurrentQuestion = (state) => state.listeningPractice.currentQuestion;
export const selectTimeRemaining = (state) => state.listeningPractice.timeRemaining;
export const selectAudioDetails = (state) => state.listeningPractice.audioDetails;
export const selectAudioProgress = (state) => state.listeningPractice.audioProgress;
export const selectTranscript = (state) => state.listeningPractice.transcript;

export const {
  setCurrentTest,
  setAnswer,
  setCurrentSection,
  setCurrentQuestion,
  setTimeRemaining,
  setLoading,
  setError,
  clearError,
  setAudioDetails,
  updateAudioProgress,
  setCurrentSession,
  setCurrentAudio,
  setTranscript,
  resetPractice,
} = listeningPracticeSlice.actions;

export default listeningPracticeSlice.reducer;
