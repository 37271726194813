import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

function Logo({ variant = 'full', sx = {} }) {
  const theme = useTheme();
  const uniqueId = React.useId();

  const LogoSvg = ({ size = variant === 'icon' ? 32 : 40 }) => (
    <svg 
      width={size} 
      height={size} 
      viewBox="0 0 512 512" 
      xmlns="http://www.w3.org/2000/svg"
      style={{ 
        filter: theme.palette.mode === 'dark' ? 'brightness(1)' : 'brightness(1)',
        marginTop: '2px'
      }}
    >
      <defs>
        <linearGradient id={`grad-${uniqueId}`} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#FFD700" stopOpacity="1" />
          <stop offset="100%" stopColor="#FFA500" stopOpacity="1" />
        </linearGradient>
        <filter id={`shadow-${uniqueId}`}>
          <feDropShadow dx="0" dy="4" stdDeviation="8" floodOpacity="0.2"/>
        </filter>
      </defs>
      <circle 
        cx="256" 
        cy="256" 
        r="240" 
        fill={`url(#grad-${uniqueId})`} 
        filter={`url(#shadow-${uniqueId})`} 
      />
      <g transform="translate(256 256) scale(0.85) translate(-256 -256)">
        <path 
          d="M256 100 L396 340 L116 340 Z" 
          fill="#F8F9FA" 
          filter={`url(#shadow-${uniqueId})`} 
        />
        <circle 
          cx="256" 
          cy="220" 
          r="45" 
          fill="#F8F9FA" 
          filter={`url(#shadow-${uniqueId})`} 
        />
      </g>
    </svg>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        color: 'inherit',
        ...sx
      }}
    >
      <LogoSvg />
      {variant === 'full' && (
        <Typography
          variant="h6"
          component="span"
          sx={{
            fontWeight: 700,
            color: 'white',
            textShadow: '0 2px 4px rgba(0,0,0,0.15)',
            lineHeight: { xs: 1.1, sm: 'inherit' },
            fontSize: { xs: '1.2rem', sm: '1.25rem' }
          }}
        >
          IELTS Tutor
        </Typography>
      )}
    </Box>
  );
}

export default Logo;
