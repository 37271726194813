import { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

function Timer({ initialTime, onTimeEnd }) {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isWarning, setIsWarning] = useState(false);

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeEnd();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        const newTime = prev - 1;
        // Set warning when 5 minutes remaining
        if (newTime <= 300 && !isWarning) {
          setIsWarning(true);
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onTimeEnd, isWarning]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const progress = (timeLeft / initialTime) * 100;

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={progress}
        size={60}
        thickness={5}
        sx={{
          color: isWarning ? 'warning.main' : 'primary.main',
          '& .MuiCircularProgress-svg': {
            transform: 'scale(1)',
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{
            color: isWarning ? 'warning.main' : 'text.primary',
            fontWeight: 'bold',
          }}
        >
          {formatTime(timeLeft)}
        </Typography>
      </Box>
    </Box>
  );
}

Timer.propTypes = {
  initialTime: PropTypes.number.isRequired,
  onTimeEnd: PropTypes.func.isRequired,
};

export default Timer;
