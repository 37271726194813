import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

const faqs = [
  {
    question: 'What is included in the free trial?',
    answer: 'The 7-day free trial gives you full access to all premium features including AI Tutor, practice tests, and performance analytics. No credit card is required to start your trial.',
  },
  {
    question: 'Can I cancel my subscription anytime?',
    answer: 'Yes, you can cancel your monthly subscription at any time. Once cancelled, you\'ll continue to have access until the end of your current billing period.',
  },
  {
    question: 'What\'s the difference between monthly and lifetime access?',
    answer: 'Monthly access requires a recurring payment of $14.95 per month, while lifetime access is a one-time payment of $149 that gives you unlimited access forever. Lifetime access also includes priority support and early access to new features.',
  },
  {
    question: 'How does the AI Tutor work?',
    answer: 'The AI Tutor uses advanced speech recognition and artificial intelligence to provide real-time feedback on your speaking practice. It analyzes your pronunciation, fluency, and content to help improve your IELTS speaking score.',
  },
  {
    question: 'Are the practice tests updated regularly?',
    answer: 'Yes, we regularly update our practice tests to ensure they match the latest IELTS exam patterns. All subscribers get immediate access to new practice materials as they\'re added.',
  },
  {
    question: 'What payment methods do you accept?',
    answer: 'We process all payments securely through PayPal, which accepts major credit cards, debit cards, and PayPal balance. Your payment information is protected by PayPal\'s industry-leading security.',
  },
  {
    question: 'Is there a limit to how much I can practice?',
    answer: 'No, both monthly and lifetime subscribers get unlimited access to all practice tests. For AI Tutor sessions, there\'s a daily limit of 1 hour to ensure fair usage for all users.',
  },
  {
    question: 'What if I\'m not satisfied with the service?',
    answer: 'We offer a 7-day free trial so you can fully evaluate our platform. If you\'re not satisfied with your paid subscription, contact our support team within 7 days of purchase for a full refund.',
  },
];

function FAQSection() {
  return (
    <Box sx={{ py: 8, bgcolor: 'grey.50' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ mb: 4 }}
        >
          Frequently Asked Questions
        </Typography>

        <Box sx={{ maxWidth: 800, mx: 'auto' }}>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                mb: 1,
                '&:before': { display: 'none' },
                boxShadow: 1,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.02)',
                  },
                }}
              >
                <Typography variant="subtitle1" fontWeight="medium">
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="text.secondary">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default FAQSection;
