import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const initialState = {
  subscription: null,
  loading: false,
  error: null,
};

export const loadSubscriptionData = createAsyncThunk(
  'subscription/loadData',
  async (userId) => {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().subscription || null;
    }
    return null;
  }
);

export const startTrial = createAsyncThunk(
  'subscription/startTrial',
  async (userId) => {
    const userRef = doc(db, 'users', userId);
    const trialData = {
      status: 'trial',
      startDate: new Date().toISOString(),
      endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(), // 7 days trial
      plan: 'trial',
      updatedAt: new Date().toISOString()
    };
    
    await updateDoc(userRef, {
      subscription: trialData
    });
    
    const updatedDoc = await getDoc(userRef);
    return updatedDoc.data().subscription;
  }
);

export const startSubscription = createAsyncThunk(
  'subscription/start',
  async ({ userId, plan }) => {
    const userRef = doc(db, 'users', userId);
    const subscriptionData = {
      status: 'pending',
      plan,
      startedAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };
    
    await updateDoc(userRef, {
      subscription: subscriptionData
    });
    
    const updatedDoc = await getDoc(userRef);
    return updatedDoc.data().subscription;
  }
);

export const setSubscriptionData = createAsyncThunk(
  'subscription/setData',
  async ({ userId, subscriptionData }) => {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      subscription: {
        ...subscriptionData,
        updatedAt: new Date().toISOString()
      }
    });
    const updatedDoc = await getDoc(userRef);
    return updatedDoc.data().subscription;
  }
);

export const completeSubscription = createAsyncThunk(
  'subscription/complete',
  async ({ userId, paymentDetails, plan }) => {
    const userRef = doc(db, 'users', userId);
    const subscriptionData = {
      status: 'active',
      plan,
      startDate: new Date().toISOString(),
      endDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(), // 30 days from now
      paymentDetails,
      updatedAt: new Date().toISOString()
    };
    
    await updateDoc(userRef, {
      subscription: subscriptionData
    });
    
    const updatedDoc = await getDoc(userRef);
    return updatedDoc.data().subscription;
  }
);

export const failSubscription = createAsyncThunk(
  'subscription/fail',
  async ({ userId, error }) => {
    const userRef = doc(db, 'users', userId);
    const subscriptionData = {
      status: 'failed',
      error: error.message || 'Payment failed',
      failedAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };
    
    await updateDoc(userRef, {
      subscription: {
        ...subscriptionData,
        lastFailure: {
          date: new Date().toISOString(),
          reason: error.message || 'Payment failed'
        }
      }
    });
    
    const updatedDoc = await getDoc(userRef);
    return updatedDoc.data().subscription;
  }
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancel',
  async (userId) => {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      'subscription.status': 'cancelled',
      'subscription.cancelledAt': new Date().toISOString()
    });
    const updatedDoc = await getDoc(userRef);
    return updatedDoc.data().subscription;
  }
);

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = null;
    },
    resetSubscription: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSubscriptionData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadSubscriptionData.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(loadSubscriptionData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(startTrial.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startTrial.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(startTrial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(startSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(startSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(setSubscriptionData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setSubscriptionData.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(setSubscriptionData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(completeSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(completeSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(failSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(failSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(failSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setSubscription,
  setLoading,
  setError,
  clearError,
  resetSubscription,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
