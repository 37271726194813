import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Chip,
  Grid,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import {
  CheckCircle as ActiveIcon,
  Cancel as InactiveIcon,
  AccessTime as TimeIcon,
  Stars as FeaturesIcon,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cancelSubscription } from '../../store/slices/subscriptionSlice';

// Format date with fallback for invalid dates
const formatDate = (date) => {
  if (!date) return 'N/A';
  
  try {
    // Handle Firestore Timestamp
    if (date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    
    // Handle Date object
    if (date instanceof Date && !isNaN(date)) {
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    
    console.log('Invalid date input:', date);
    return 'N/A';
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'N/A';
  }
};

function SubscriptionDetails() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.subscription);
  const { user } = useSelector((state) => state.auth);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Debug log
  useEffect(() => {
    console.log('Current subscription state:', {
      ...subscription,
      purchaseDate: subscription.purchaseDate ? formatDate(subscription.purchaseDate) : 'N/A',
      subscriptionEndDate: subscription.subscriptionEndDate ? formatDate(subscription.subscriptionEndDate) : 'N/A',
    });
  }, [subscription]);

  const { 
    isSubscribed,
    subscriptionType,
    subscriptionEndDate,
    trialEndDate,
    purchaseDate,
    paymentStatus 
  } = subscription;

  const getStatusColor = () => {
    if (!isSubscribed) return theme.palette.error.main;
    if (subscriptionType === 'trial') return theme.palette.warning.main;
    return theme.palette.success.main;
  };

  const getStatusIcon = () => {
    return isSubscribed ? (
      <ActiveIcon sx={{ color: theme.palette.success.main }} />
    ) : (
      <InactiveIcon sx={{ color: theme.palette.error.main }} />
    );
  };

  const getFeatures = () => {
    switch (subscriptionType) {
      case 'premium':
        return [
          'Unlimited AI Tutor Sessions',
          'All Practice Types',
          'Detailed Performance Analytics',
          'Priority Support',
        ];
      case 'basic':
        return [
          '10 AI Tutor Sessions/month',
          'Basic Practice Types',
          'Basic Performance Tracking',
          'Email Support',
        ];
      case 'trial':
        return [
          'Limited AI Tutor Sessions',
          'Basic Practice Types',
          'Basic Performance Tracking',
          '7-Day Trial Access',
        ];
      default:
        return [
          'Limited AI Tutor Sessions',
          'Basic Practice Types',
          'Basic Performance Tracking',
        ];
    }
  };

  const getStatusText = () => {
    if (!isSubscribed) return 'INACTIVE';
    if (subscriptionType === 'trial') return 'TRIAL';
    return 'ACTIVE';
  };

  const getStartDate = () => {
    if (!purchaseDate) return 'N/A';
    console.log('Formatting purchase date:', purchaseDate);
    return formatDate(purchaseDate);
  };

  const getExpiryDate = () => {
    if (subscriptionType === 'trial' && trialEndDate) {
      console.log('Formatting trial end date:', trialEndDate);
      return formatDate(trialEndDate);
    }
    if (subscriptionEndDate) {
      console.log('Formatting subscription end date:', subscriptionEndDate);
      return formatDate(subscriptionEndDate);
    }
    return 'N/A';
  };

  const handleUpgrade = () => {
    navigate('/pricing');
  };

  const handleCancel = async () => {
    setLoading(true);
    setError('');
    try {
      await dispatch(cancelSubscription(user.uid)).unwrap();
      setShowCancelDialog(false);
    } catch (err) {
      console.error('Error canceling subscription:', err);
      setError('Failed to cancel subscription. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {getStatusIcon()}
                <Typography variant="h6" sx={{ ml: 1 }}>
                  Subscription Status
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Chip
                  label={getStatusText()}
                  sx={{
                    backgroundColor: getStatusColor(),
                    color: 'white',
                    mr: 2,
                  }}
                />
                {subscriptionType && (
                  <Chip
                    label={subscriptionType.toUpperCase()}
                    variant="outlined"
                    color="primary"
                  />
                )}
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TimeIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />
                <Typography variant="body2" color="text.secondary">
                  Started: {getStartDate()}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TimeIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />
                <Typography variant="body2" color="text.secondary">
                  Expires: {getExpiryDate()}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <FeaturesIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                  <Typography variant="subtitle1">Features</Typography>
                </Box>
                <Grid container spacing={1}>
                  {getFeatures().map((feature, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography variant="body2" color="text.secondary">
                        • {feature}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            {!isSubscribed ? (
              <Button
                variant="contained"
                onClick={handleUpgrade}
                sx={{
                  py: 1.5,
                  px: 4,
                  background: theme.palette.gradient.primary,
                  '&:hover': {
                    background: theme.palette.gradient.primary,
                    opacity: 0.9,
                  },
                }}
              >
                Upgrade Now
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={handleUpgrade}
                >
                  Change Plan
                </Button>
                {subscriptionType !== 'trial' && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setShowCancelDialog(true)}
                  >
                    Cancel Subscription
                  </Button>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Dialog open={showCancelDialog} onClose={() => setShowCancelDialog(false)}>
        <DialogTitle>Cancel Subscription</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel your subscription? You will lose access to premium features at the end of your current billing period.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCancelDialog(false)}>
            Keep Subscription
          </Button>
          <Button 
            color="error"
            onClick={handleCancel}
            disabled={loading}
          >
            {loading ? 'Canceling...' : 'Cancel Subscription'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SubscriptionDetails;
