import { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setAudioDetails, updateAudioProgress } from '../store/slices/listeningPracticeSlice';

const useAudioControl = (audioUrl) => {
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Clean up blob URLs when component unmounts or URL changes
  useEffect(() => {
    return () => {
      if (audioUrl?.startsWith('blob:')) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  useEffect(() => {
    if (audioRef.current) {
      // Reset state when audio source changes
      setIsPlaying(false);
      setCurrentTime(0);
      setDuration(0);
      setIsLoading(true);
      setError(null);

      // Set up audio event listeners
      const audio = audioRef.current;

      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
        setIsLoading(false);
        dispatch(setAudioDetails({
          duration: audio.duration,
          isPlaying: false,
          currentTime: 0
        }));
      };

      const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime);
        dispatch(updateAudioProgress(audio.currentTime));
      };

      const handleEnded = () => {
        setIsPlaying(false);
        dispatch(setAudioDetails({ isPlaying: false }));
      };

      const handleError = (e) => {
        let errorMessage = 'Error loading audio file';
        if (e.target.error) {
          switch (e.target.error.code) {
            case e.target.error.MEDIA_ERR_ABORTED:
              errorMessage = 'Audio playback was aborted';
              break;
            case e.target.error.MEDIA_ERR_NETWORK:
              errorMessage = 'Network error while loading audio';
              break;
            case e.target.error.MEDIA_ERR_DECODE:
              errorMessage = 'Audio decoding error';
              break;
            case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
              errorMessage = 'Audio format not supported';
              break;
          }
        }
        setError(errorMessage);
        setIsLoading(false);
        console.error('Audio error:', e, errorMessage);
      };

      const handleLoadStart = () => {
        setIsLoading(true);
        setError(null);
      };

      const handleCanPlayThrough = () => {
        setIsLoading(false);
      };

      audio.addEventListener('loadstart', handleLoadStart);
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('canplaythrough', handleCanPlayThrough);
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('ended', handleEnded);
      audio.addEventListener('error', handleError);

      return () => {
        audio.removeEventListener('loadstart', handleLoadStart);
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('canplaythrough', handleCanPlayThrough);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('ended', handleEnded);
        audio.removeEventListener('error', handleError);
      };
    }
  }, [audioUrl, dispatch]);

  const togglePlay = useCallback(() => {
    if (!audioRef.current) return;

    if (audioRef.current.paused) {
      audioRef.current.play()
        .then(() => {
          setIsPlaying(true);
          dispatch(setAudioDetails({ isPlaying: true }));
        })
        .catch(error => {
          console.error('Error playing audio:', error);
          setError('Error playing audio: ' + error.message);
        });
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
      dispatch(setAudioDetails({ isPlaying: false }));
    }
  }, [dispatch]);

  const seek = useCallback((time) => {
    if (!audioRef.current) return;

    const newTime = Math.min(Math.max(time, 0), duration);
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
    dispatch(updateAudioProgress(newTime));
  }, [duration, dispatch]);

  const seekByPercentage = useCallback((percentage) => {
    if (!audioRef.current || !duration) return;

    const newTime = (percentage / 100) * duration;
    seek(newTime);
  }, [duration, seek]);

  const replay = useCallback((seconds = 5) => {
    if (!audioRef.current) return;

    const newTime = Math.max(currentTime - seconds, 0);
    seek(newTime);
  }, [currentTime, seek]);

  const forward = useCallback((seconds = 5) => {
    if (!audioRef.current) return;

    const newTime = Math.min(currentTime + seconds, duration);
    seek(newTime);
  }, [currentTime, duration, seek]);

  const formatTime = useCallback((timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, []);

  return {
    audioRef,
    isPlaying,
    duration,
    currentTime,
    isLoading,
    error,
    controls: {
      togglePlay,
      seek,
      seekByPercentage,
      replay,
      forward
    },
    formatTime
  };
};

export default useAudioControl;
