import { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const TranscriptDisplay = ({ transcript, questions }) => {
  const [expanded, setExpanded] = useState(false);
  
  // Function to highlight text related to questions
  const highlightText = (text) => {
    if (!questions) return text;
    
    let highlightedText = text;
    questions.forEach((question) => {
      // Create a regex that matches the answer while preserving case
      const regex = new RegExp(`(${question.correctAnswer})`, 'gi');
      highlightedText = highlightedText.replace(
        regex,
        '<mark style="background-color: #e3f2fd;">$1</mark>'
      );
    });
    
    return highlightedText;
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(transcript);
    } catch (err) {
      console.error('Failed to copy transcript:', err);
    }
  };

  return (
    <Paper elevation={2} sx={{ mt: 2 }}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{ width: '100%' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ 
            '&.Mui-expanded': {
              minHeight: 48,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            width: '100%',
            pr: 2
          }}>
            <Typography variant="h6">Transcript</Typography>
            <Tooltip title="Copy transcript">
              <IconButton 
                size="small" 
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopy();
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            component="div"
            sx={{ 
              whiteSpace: 'pre-wrap',
              '& mark': {
                borderRadius: '2px'
              }
            }}
            dangerouslySetInnerHTML={{
              __html: highlightText(transcript)
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default TranscriptDisplay;
