import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  useTheme,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import {
  updateProfile,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  deleteUser,
} from 'firebase/auth';
import { doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { updateUser, logoutUser } from '../store/slices/auth';
import { setSubscriptionData } from '../store/slices/subscriptionSlice';
import SubscriptionDetails from '../components/profile/SubscriptionDetails';
import PracticeHistory from '../components/profile/PracticeHistory';

function TabPanel({ children, value, index }) {
  return value === index ? <Box sx={{ py: 3 }}>{children}</Box> : null;
}

function Profile() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  
  const [activeTab, setActiveTab] = useState(0);
  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [deleteConfirmPassword, setDeleteConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  // Load subscription data when component mounts
  useEffect(() => {
    const loadSubscriptionData = async () => {
      if (user?.uid) {
        try {
          console.log('Loading subscription data for user:', user.uid);
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('Raw user data:', userData);
            
            if (userData.subscription) {
              console.log('Raw subscription data:', userData.subscription);
              console.log('Raw startedAt:', userData.subscription.startedAt);
              console.log('Raw expiresAt:', userData.subscription.expiresAt);
              
              // Directly set subscription data
              dispatch(setSubscriptionData({ subscription: userData.subscription }));
            } else {
              console.log('No subscription data found');
            }
          } else {
            console.log('User document not found');
          }
        } catch (error) {
          console.error('Error loading subscription:', error);
        }
      }
    };
    
    loadSubscriptionData();
  }, [dispatch, user?.uid]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setError('');
    setSuccess('');
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      await updateProfile(auth.currentUser, {
        displayName: displayName,
      });

      await updateDoc(doc(db, 'users', user.uid), {
        displayName: displayName,
        updatedAt: new Date(),
      });

      dispatch(updateUser({
        displayName: displayName,
      }));

      setSuccess('Profile updated successfully!');
    } catch (err) {
      console.error('Profile update error:', err);
      setError('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      setLoading(false);
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword);
      
      setSuccess('Password updated successfully!');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      console.error('Password update error:', err);
      setError(
        err.code === 'auth/wrong-password'
          ? 'Current password is incorrect'
          : 'Failed to update password. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAccountDelete = async () => {
    setError('');
    setLoading(true);

    try {
      const credential = EmailAuthProvider.credential(user.email, deleteConfirmPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      
      // Delete Firestore data
      await deleteDoc(doc(db, 'users', user.uid));
      
      // Delete Firebase Auth account
      await deleteUser(auth.currentUser);
      
      dispatch(logoutUser());
      navigate('/');
    } catch (err) {
      console.error('Account deletion error:', err);
      setError(
        err.code === 'auth/wrong-password'
          ? 'Password is incorrect'
          : 'Failed to delete account. Please try again.'
      );
    } finally {
      setLoading(false);
      setShowDeleteDialog(false);
    }
  };

  if (!user) {
    navigate('/login');
    return null;
  }

  return (
    <Container maxWidth="md" sx={{ mt: { xs: 4, sm: 8 }, mb: 4 }}>
      <Paper 
        elevation={3} 
        sx={{ 
          borderRadius: 2,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Profile" />
            <Tab label="Subscription" />
            <Tab label="Security" />
            <Tab label="Practice History" />
          </Tabs>
        </Box>

        {error && (
          <Alert severity="error" sx={{ m: 3 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ m: 3 }}>
            {success}
          </Alert>
        )}

        <Box sx={{ p: { xs: 3, sm: 4 } }}>
          <TabPanel value={activeTab} index={0}>
            <Typography 
              variant="h5" 
              gutterBottom
              sx={{
                background: theme.palette.gradient.primary,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                mb: 3,
              }}
            >
              Profile Settings
            </Typography>

            <form onSubmit={handleProfileUpdate}>
              <TextField
                label="Email"
                type="email"
                value={user.email}
                disabled
                fullWidth
                sx={{ mb: 2 }}
              />

              <TextField
                label="Display Name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                fullWidth
                sx={{ mb: 3 }}
              />

              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  py: 1.5,
                  background: theme.palette.gradient.primary,
                  '&:hover': {
                    background: theme.palette.gradient.primary,
                    opacity: 0.9,
                  },
                }}
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </Button>
            </form>
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <Typography 
              variant="h5" 
              gutterBottom
              sx={{
                background: theme.palette.gradient.primary,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                mb: 3,
              }}
            >
              Subscription
            </Typography>

            <SubscriptionDetails />
          </TabPanel>

          <TabPanel value={activeTab} index={2}>
            <Typography 
              variant="h5" 
              gutterBottom
              sx={{
                background: theme.palette.gradient.primary,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                mb: 3,
              }}
            >
              Security Settings
            </Typography>

            <form onSubmit={handlePasswordChange}>
              <TextField
                label="Current Password"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                fullWidth
                required
                sx={{ mb: 2 }}
              />

              <TextField
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                required
                sx={{ mb: 2 }}
              />

              <TextField
                label="Confirm New Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                required
                sx={{ mb: 3 }}
              />

              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  py: 1.5,
                  background: theme.palette.gradient.primary,
                  '&:hover': {
                    background: theme.palette.gradient.primary,
                    opacity: 0.9,
                  },
                }}
              >
                {loading ? 'Updating...' : 'Update Password'}
              </Button>
            </form>

            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" color="error" gutterBottom>
                Danger Zone
              </Typography>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setShowDeleteDialog(true)}
              >
                Delete Account
              </Button>
            </Box>
          </TabPanel>

          <TabPanel value={activeTab} index={3}>
            <Typography 
              variant="h5" 
              gutterBottom
              sx={{
                background: theme.palette.gradient.primary,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                mb: 3,
              }}
            >
              Practice History
            </Typography>

            <PracticeHistory userId={user.uid} />
          </TabPanel>
        </Box>
      </Paper>

      {/* Delete Account Dialog */}
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action cannot be undone. All your data will be permanently deleted.
            Please enter your password to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={deleteConfirmPassword}
            onChange={(e) => setDeleteConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleAccountDelete} 
            color="error"
            disabled={loading || !deleteConfirmPassword}
          >
            {loading ? 'Deleting...' : 'Delete Account'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Profile;
