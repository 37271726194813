import { Box, Typography } from '@mui/material';

function WritingPractice() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Writing Practice
      </Typography>
      <Typography variant="body1">
        Writing practice section is under development.
      </Typography>
    </Box>
  );
}

export default WritingPractice;
