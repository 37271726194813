import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Alert,
  useTheme,
} from '@mui/material';
import {
  BarChart as SessionsIcon,
  EmojiEvents as ScoreIcon,
  Timer as TimeIcon,
  Update as LastPracticeIcon,
} from '@mui/icons-material';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

function StatCard({ title, value, icon: Icon, color }) {
  const theme = useTheme();
  
  return (
    <Card sx={{ height: '100%', textAlign: 'center' }}>
      <CardContent>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1
        }}>
          <Icon sx={{ 
            color: color,
            fontSize: '3rem',
            mb: 1
          }} />
          <Typography 
            variant="h6" 
            color="text.secondary"
            sx={{ fontWeight: 500 }}
          >
            {title}
          </Typography>
          <Typography 
            variant="h3" 
            sx={{ 
              fontWeight: 'bold',
              color: theme.palette.text.primary
            }}
          >
            {value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

function PracticeHistory({ userId }) {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalSessions: 0,
    averageScore: 0,
    totalTime: 0,
    lastPracticeDate: null,
  });

  useEffect(() => {
    let mounted = true;

    const fetchPracticeHistory = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        const practiceRef = collection(db, 'practiceSessions');
        const q = query(
          practiceRef,
          where('userId', '==', userId),
          orderBy('timestamp', 'desc')
        );

        const snapshot = await getDocs(q);
        
        if (!mounted) return;

        let totalScore = 0;
        let totalTime = 0;
        let sessionsCount = 0;
        let lastDate = null;

        snapshot.forEach((doc) => {
          const data = doc.data();
          if (data.score) totalScore += data.score;
          if (data.duration) totalTime += data.duration;
          sessionsCount++;
          
          // Get the first timestamp since we're already ordering by desc
          if (!lastDate && data.timestamp) {
            lastDate = data.timestamp.toDate();
          }
        });

        setStats({
          totalSessions: sessionsCount,
          averageScore: sessionsCount > 0 ? (totalScore / sessionsCount).toFixed(1) : 0,
          totalTime: Math.round(totalTime / 60), // Convert to minutes
          lastPracticeDate: lastDate,
        });
        setError(null);
      } catch (err) {
        console.error('Error fetching practice history:', err);
        if (mounted) {
          if (err.code === 'failed-precondition') {
            setError('Please wait while we set up the database indexes. This may take a few minutes.');
          } else {
            setError('Failed to load practice history. Please try again later.');
          }
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchPracticeHistory();

    return () => {
      mounted = false;
    };
  }, [userId]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 3 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Sessions"
            value={stats.totalSessions}
            icon={SessionsIcon}
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Average Score"
            value={stats.averageScore}
            icon={ScoreIcon}
            color={theme.palette.success.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Practice Time"
            value={`${stats.totalTime}m`}
            icon={TimeIcon}
            color={theme.palette.info.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Last Practice"
            value={stats.lastPracticeDate ? 
              stats.lastPracticeDate.toLocaleDateString() : 
              'Never'
            }
            icon={LastPracticeIcon}
            color={theme.palette.warning.main}
          />
        </Grid>
      </Grid>

      {stats.totalSessions === 0 && (
        <Alert severity="info" sx={{ mt: 4 }}>
          No practice sessions recorded yet. Start practicing to see your progress!
        </Alert>
      )}
    </Box>
  );
}

export default PracticeHistory;
