import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Speed as SpeedIcon,
  Psychology as PsychologyIcon,
  Headset as HeadsetIcon,
  Update as UpdateIcon,
} from '@mui/icons-material';
import { benefits } from './subscriptionPlans';

const benefitIcons = {
  'Instant Access': SpeedIcon,
  'AI-Powered Feedback': PsychologyIcon,
  'Priority Support': HeadsetIcon,
  'Regular Updates': UpdateIcon,
};

function BenefitsSection() {
  const theme = useTheme();

  return (
    <Box sx={{ mt: 8, mb: 4 }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          background: theme.palette.gradient.secondary,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          mb: 4,
        }}
      >
        What You'll Get
      </Typography>
      <Grid container spacing={3}>
        {benefits.map((benefit) => {
          const Icon = benefitIcons[benefit.title];
          return (
            <Grid item xs={12} sm={6} md={3} key={benefit.title}>
              <Paper
                sx={{
                  p: 3,
                  height: '100%',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <Box
                    sx={{
                      color: 'primary.main',
                      mb: 2,
                    }}
                  >
                    <Icon />
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {benefit.description}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default BenefitsSection;
