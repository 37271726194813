import { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { openaiService } from '../services/openaiService';

export function useListeningTestGenerator() {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [generatedTest, setGeneratedTest] = useState(null);

  const generateTest = async (topic) => {
    setIsGenerating(true);
    setError(null);
    setGeneratedTest(null);

    try {
      // Generate the test using OpenAI service
      const test = await openaiService.createListeningTest(topic);

      // Store the test in Firestore
      const db = getFirestore();
      const testsRef = collection(db, 'listeningTests');
      const docRef = await addDoc(testsRef, {
        ...test,
        createdAt: new Date().toISOString(),
        type: 'listening',
        status: 'active'
      });

      // Add the Firestore ID to the test object
      const testWithId = {
        ...test,
        firestoreId: docRef.id
      };

      setGeneratedTest(testWithId);
      return testWithId;
    } catch (err) {
      console.error('Error generating listening test:', err);
      setError(err.message);
      throw err;
    } finally {
      setIsGenerating(false);
    }
  };

  const getTestById = async (testId) => {
    try {
      const db = getFirestore();
      const testDoc = await db.collection('listeningTests').doc(testId).get();
      
      if (!testDoc.exists) {
        throw new Error('Test not found');
      }

      return {
        ...testDoc.data(),
        firestoreId: testDoc.id
      };
    } catch (err) {
      console.error('Error fetching test:', err);
      setError(err.message);
      throw err;
    }
  };

  const listTests = async (limit = 10) => {
    try {
      const db = getFirestore();
      const testsRef = collection(db, 'listeningTests');
      const snapshot = await testsRef
        .orderBy('createdAt', 'desc')
        .limit(limit)
        .get();

      return snapshot.docs.map(doc => ({
        ...doc.data(),
        firestoreId: doc.id
      }));
    } catch (err) {
      console.error('Error listing tests:', err);
      setError(err.message);
      throw err;
    }
  };

  return {
    generateTest,
    getTestById,
    listTests,
    isGenerating,
    error,
    generatedTest
  };
}
