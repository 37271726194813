import { Container, Typography, Paper, Box } from '@mui/material';

function Terms() {
  return (
    <Container maxWidth="md">
      <Paper elevation={0} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        
        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By accessing and using IELTS Tutor, you accept and agree to be bound by the terms and provision of this agreement.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            2. User Accounts
          </Typography>
          <Typography paragraph>
            • You must be at least 13 years old to use this service<br />
            • You are responsible for maintaining the security of your account<br />
            • You are responsible for all activities that occur under your account<br />
            • You must notify us immediately of any unauthorized use of your account
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            3. Subscription and Payments
          </Typography>
          <Typography paragraph>
            • Subscription fees are charged in advance on a monthly or annual basis<br />
            • All payments are non-refundable<br />
            • You can cancel your subscription at any time<br />
            • Cancellation will be effective at the end of the current billing period
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            4. Content and Copyright
          </Typography>
          <Typography paragraph>
            • All content provided on IELTS Tutor is protected by copyright<br />
            • You may not copy, reproduce, distribute, or create derivative works from this content<br />
            • You retain ownership of any content you create or upload to the platform
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            5. Privacy
          </Typography>
          <Typography paragraph>
            Your use of IELTS Tutor is also governed by our Privacy Policy. Please review our Privacy Policy, which also governs the site and informs users of our data collection practices.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            6. Disclaimer
          </Typography>
          <Typography paragraph>
            IELTS Tutor is provided "as is" without any warranties, expressed or implied. We do not guarantee that the service will be uninterrupted or error-free.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            7. Changes to Terms
          </Typography>
          <Typography paragraph>
            We reserve the right to modify these terms at any time. We will notify users of any changes by posting the new Terms of Service on this page.
          </Typography>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="body2" color="text.secondary">
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default Terms;
