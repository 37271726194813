import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPractice: null,
  history: [],
  loading: false,
  error: null,
  sessionActive: false,
  answers: [],
  currentQuestionIndex: 0,
};

export const practiceSlice = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    setPractice: (state, action) => {
      state.currentPractice = action.payload;
    },
    addToHistory: (state, action) => {
      state.history.push(action.payload);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = null;
    },
    resetPractice: () => initialState,
    startSession: (state, action) => {
      state.sessionActive = true;
      state.currentPractice = action.payload;
      state.answers = [];
      state.currentQuestionIndex = 0;
    },
    endSession: (state) => {
      state.sessionActive = false;
      state.currentPractice = null;
      state.answers = [];
      state.currentQuestionIndex = 0;
    },
    submitAnswer: (state, action) => {
      const { answer, questionId } = action.payload;
      state.answers.push({
        questionId,
        answer,
        timestamp: Date.now()
      });
      state.currentQuestionIndex += 1;
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    }
  },
});

export const {
  setPractice,
  addToHistory,
  setLoading,
  setError,
  clearError,
  resetPractice,
  startSession,
  endSession,
  submitAnswer,
  setCurrentQuestionIndex,
} = practiceSlice.actions;

export default practiceSlice.reducer;
