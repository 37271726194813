import { 
  Box, 
  Typography, 
  Paper, 
  List, 
  ListItem, 
  ListItemText,
  Divider,
  Stack,
  Chip,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Alert
} from '@mui/material';
import { 
  Check, 
  Close, 
  ExpandMore,
  TrendingUp,
  AccessTime,
  EmojiEvents,
  School
} from '@mui/icons-material';
import useQuestionValidation from '../../hooks/useQuestionValidation';

const ResultSummary = ({ questions, answers, totalTime }) => {
  const {
    validationResults,
    statistics,
    getPerformanceSummary
  } = useQuestionValidation(questions, answers);

  const getScoreColor = (score) => {
    if (score >= 80) return 'success';
    if (score >= 60) return 'warning';
    return 'error';
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  return (
    <Stack spacing={3}>
      <Paper elevation={2} sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
              Practice Session Results
            </Typography>
            
            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={2} 
              justifyContent="center"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Chip
                icon={<School />}
                label={`IELTS Band: ${statistics.bandScore}`}
                color={getScoreColor(statistics.score)}
                size="large"
                sx={{ fontSize: '1.1rem', py: 2.5 }}
              />
              <Chip
                icon={<TrendingUp />}
                label={`Score: ${statistics.score}%`}
                color={getScoreColor(statistics.score)}
                size="large"
                sx={{ fontSize: '1.1rem', py: 2.5 }}
              />
              <Chip
                icon={<AccessTime />}
                label={`Time: ${formatTime(totalTime)}`}
                variant="outlined"
                size="large"
                sx={{ fontSize: '1.1rem', py: 2.5 }}
              />
            </Stack>

            <Alert 
              severity={statistics.score >= 70 ? "success" : "info"}
              sx={{ maxWidth: 600, mx: 'auto', mb: 2 }}
            >
              {getPerformanceSummary()}
            </Alert>
          </Box>

          <Box>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <EmojiEvents color={getScoreColor(statistics.score)} />
              Performance Breakdown
            </Typography>
            
            <Stack spacing={2}>
              <Box>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Overall Progress
                </Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={statistics.score} 
                  color={getScoreColor(statistics.score)}
                  sx={{ height: 10, borderRadius: 5 }}
                />
              </Box>

              <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={2} 
                justifyContent="space-around"
              >
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" color="success.main">
                    {statistics.correct}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Correct
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" color="error.main">
                    {statistics.incorrect}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Incorrect
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" color="text.secondary">
                    {statistics.unanswered}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Unanswered
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Paper>

      <Paper elevation={2} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Detailed Question Analysis
        </Typography>
        <List>
          {validationResults.map((result, index) => (
            <Accordion key={result.questionId} sx={{ mb: 1 }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Stack 
                  direction="row" 
                  spacing={2} 
                  alignItems="center" 
                  sx={{ width: '100%' }}
                >
                  {result.isCorrect ? (
                    <Check color="success" />
                  ) : (
                    <Close color="error" />
                  )}
                  <Typography>Question {index + 1}</Typography>
                  <Chip
                    label={result.isCorrect ? 'Correct' : 'Incorrect'}
                    size="small"
                    color={result.isCorrect ? 'success' : 'error'}
                    sx={{ ml: 'auto' }}
                  />
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <Typography variant="body1">
                    {questions[index].text}
                  </Typography>
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Your Answer:
                    </Typography>
                    <Typography color={result.isCorrect ? 'success.main' : 'error.main'}>
                      {result.userAnswer || 'Not answered'}
                    </Typography>
                  </Box>
                  {!result.isCorrect && (
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Correct Answer:
                      </Typography>
                      <Typography color="success.main">
                        {result.correctAnswer}
                      </Typography>
                    </Box>
                  )}
                  <Alert severity={result.isCorrect ? 'success' : 'error'}>
                    {result.feedback}
                  </Alert>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </List>
      </Paper>

      <Box sx={{ textAlign: 'center' }}>
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => window.location.reload()}
        >
          Start New Practice Session
        </Button>
      </Box>
    </Stack>
  );
};

export default ResultSummary;
