import { createSelector } from '@reduxjs/toolkit';

// Basic selectors
export const selectUser = (state) => state.auth.user;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectAuthLoading = (state) => state.auth.loading;
export const selectAuthError = (state) => state.auth.error;

// Memoized selectors
export const selectUserRole = createSelector(
  selectUser,
  user => user?.role || 'user'
);

export const selectIsAdmin = createSelector(
  selectUser,
  user => user?.email === 'deonmfe@gmail.com'
);

export const selectUserEmail = createSelector(
  selectUser,
  user => user?.email
);

export const selectUserDisplayName = createSelector(
  selectUser,
  user => user?.displayName || user?.email?.split('@')[0] || 'User'
);
