import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  Headphones as ListeningIcon,
  MenuBook as ReadingIcon,
  Edit as WritingIcon,
  RecordVoiceOver as SpeakingIcon,
  Timeline as TimelineIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import { selectUser } from '../store/slices/auth';

function Dashboard() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { stats, progress } = useSelector((state) => state.practice);

  const modules = [
    {
      title: 'Listening',
      icon: <ListeningIcon />,
      path: '/practice/listening',
      color: '#1976d2',
      progress: stats.listening.averageScore,
    },
    {
      title: 'Reading',
      icon: <ReadingIcon />,
      path: '/practice/reading',
      color: '#2e7d32',
      progress: stats.reading.averageScore,
    },
    {
      title: 'Writing',
      icon: <WritingIcon />,
      path: '/practice/writing',
      color: '#9c27b0',
      progress: stats.writing.averageScore,
    },
    {
      title: 'Speaking',
      icon: <SpeakingIcon />,
      path: '/practice/speaking',
      color: '#ed6c02',
      progress: stats.speaking.averageScore,
    },
  ];

  // Get recent activities from all modules
  const recentActivities = Object.entries(progress).flatMap(([type, activities]) =>
    activities.map((activity) => ({
      ...activity,
      type,
    }))
  ).sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 5);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 6 }}>
        <Typography variant="h4" gutterBottom>
          Welcome back, {user?.displayName || user?.email?.split('@')[0] || 'Student'}!
        </Typography>

        <Grid container spacing={4}>
          {/* Progress Overview */}
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Your Progress
              </Typography>
              <Grid container spacing={3}>
                {modules.map((module) => (
                  <Grid item xs={12} sm={6} md={3} key={module.title}>
                    <Card>
                      <CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <Box sx={{ color: module.color }}>{module.icon}</Box>
                          <Typography variant="h6" sx={{ ml: 1 }}>
                            {module.title}
                          </Typography>
                        </Box>
                        <Box sx={{ mb: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={module.progress}
                            sx={{
                              height: 8,
                              borderRadius: 4,
                              backgroundColor: `${module.color}20`,
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: module.color,
                              },
                            }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2" color="text.secondary">
                            Score: {module.progress.toFixed(1)}
                          </Typography>
                          <Button
                            size="small"
                            onClick={() => navigate(module.path)}
                            sx={{ color: module.color }}
                          >
                            Practice
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>

          {/* Recent Activities */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Recent Activities
              </Typography>
              <List>
                {recentActivities.map((activity, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <TimelineIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${activity.type.charAt(0).toUpperCase() + activity.type.slice(1)} Practice`}
                      secondary={new Date(activity.date).toLocaleDateString()}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <StarIcon sx={{ color: 'gold', mr: 1 }} />
                      <Typography>{activity.score.toFixed(1)}</Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>

          {/* Quick Stats */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Quick Stats
              </Typography>
              <Grid container spacing={2}>
                {Object.entries(stats).map(([type, stat]) => (
                  <Grid item xs={12} sm={6} key={type}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Tests Completed: {stat.completed}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Average Score: {stat.averageScore.toFixed(1)}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Dashboard;
