import { useEffect, useCallback } from 'react';

const useAudioKeyboardControls = (controls) => {
  const { togglePlay, replay, forward } = controls;

  const handleKeyPress = useCallback((event) => {
    // Ignore key events if user is typing in an input field
    if (event.target.tagName === 'INPUT' || 
        event.target.tagName === 'TEXTAREA' ||
        event.target.isContentEditable) {
      return;
    }

    switch (event.code) {
      case 'Space':
        event.preventDefault();
        togglePlay();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        replay(5);
        break;
      case 'ArrowRight':
        event.preventDefault();
        forward(5);
        break;
      case 'Digit1':
      case 'Numpad1':
        event.preventDefault();
        replay(10);
        break;
      case 'Digit2':
      case 'Numpad2':
        event.preventDefault();
        forward(10);
        break;
      default:
        break;
    }
  }, [togglePlay, replay, forward]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return {
    keyboardShortcuts: [
      { key: 'Space', description: 'Play/Pause' },
      { key: '←', description: 'Rewind 5 seconds' },
      { key: '→', description: 'Forward 5 seconds' },
      { key: '1', description: 'Rewind 10 seconds' },
      { key: '2', description: 'Forward 10 seconds' }
    ]
  };
};

export default useAudioKeyboardControls;
