import { useState } from 'react';

export function useGeminiChat() {
  const [error, setError] = useState(null);

  const generateResponse = async (message, context) => {
    try {
      // For now, return a mock response since we don't have the actual Gemini API integration yet
      const mockResponses = [
        "Based on your performance, I notice you did well with main idea questions. However, you might want to practice more with detail-oriented questions. Try active reading techniques like underlining key information.",
        "Looking at your answers, time management seems to be a challenge. Consider dividing your 60 minutes strategically: spend about 20 minutes on each section, leaving some time for review.",
        "Your responses show good understanding of explicit information, but you might need more practice with inference questions. Try to look for clues and connections between different parts of the text.",
        "I see you're strong with vocabulary-based questions. To improve further, focus on understanding context clues when encountering unfamiliar words.",
      ];

      // Simulate API delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Return a random mock response
      return mockResponses[Math.floor(Math.random() * mockResponses.length)];

    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  return {
    generateResponse,
    error,
  };
}
