import { 
  Box, 
  Typography, 
  Paper, 
  IconButton, 
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useTheme
} from '@mui/material';
import { 
  Help,
  Keyboard,
  PlayArrow,
  Pause,
  SkipPrevious,
  SkipNext,
  VolumeUp,
  SpaceBar,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Looks5,
  LooksOne,
  LooksTwo
} from '@mui/icons-material';
import { useState } from 'react';

const KeyboardKey = ({ children }) => {
  const theme = useTheme();
  
  return (
    <Box
      component="span"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 30,
        height: 30,
        padding: '4px 8px',
        borderRadius: 1,
        backgroundColor: theme.palette.grey[100],
        border: `1px solid ${theme.palette.grey[300]}`,
        color: theme.palette.text.primary,
        fontFamily: 'monospace',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        mx: 0.5
      }}
    >
      {children}
    </Box>
  );
};

const ControlsHelp = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const controls = [
    {
      category: 'Basic Controls',
      items: [
        {
          icon: <SpaceBar />,
          label: 'Play/Pause',
          shortcut: <KeyboardKey>Space</KeyboardKey>
        },
        {
          icon: <KeyboardArrowLeft />,
          label: 'Rewind 5 seconds',
          shortcut: <KeyboardKey>←</KeyboardKey>
        },
        {
          icon: <KeyboardArrowRight />,
          label: 'Forward 5 seconds',
          shortcut: <KeyboardKey>→</KeyboardKey>
        }
      ]
    },
    {
      category: 'Advanced Controls',
      items: [
        {
          icon: <LooksOne />,
          label: 'Rewind 10 seconds',
          shortcut: <KeyboardKey>1</KeyboardKey>
        },
        {
          icon: <LooksTwo />,
          label: 'Forward 10 seconds',
          shortcut: <KeyboardKey>2</KeyboardKey>
        },
        {
          icon: <Looks5 />,
          label: 'Jump to section',
          shortcut: <KeyboardKey>1-4</KeyboardKey>
        }
      ]
    }
  ];

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-label="Show controls help"
        color={open ? 'primary' : 'default'}
      >
        <Help />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            width: 320,
            maxHeight: 400,
            overflow: 'auto'
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Keyboard fontSize="small" />
            Keyboard Shortcuts & Controls
          </Typography>

          {controls.map((section, index) => (
            <Box key={section.category} sx={{ mt: index > 0 ? 2 : 0 }}>
              <Typography 
                variant="overline" 
                color="text.secondary"
                sx={{ display: 'block', mb: 1 }}
              >
                {section.category}
              </Typography>
              <List dense disablePadding>
                {section.items.map((item, itemIndex) => (
                  <ListItem 
                    key={itemIndex}
                    sx={{ 
                      py: 1,
                      borderRadius: 1,
                      '&:hover': {
                        bgcolor: theme.palette.action.hover
                      }
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText 
                      primary={item.label}
                      secondary={item.shortcut}
                      secondaryTypographyProps={{
                        component: 'div',
                        sx: { mt: 0.5 }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}

          <Divider sx={{ my: 2 }} />

          <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
            Tip: Use these shortcuts to navigate through the listening test more efficiently.
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default ControlsHelp;
