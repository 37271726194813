import { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { useListeningTestGenerator } from '../../hooks/useListeningTestGenerator';

export default function ListeningTestGenerator() {
  const [topic, setTopic] = useState('');
  const [recentTests, setRecentTests] = useState([]);
  const {
    generateTest,
    listTests,
    isGenerating,
    error,
    generatedTest
  } = useListeningTestGenerator();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!topic.trim()) return;

    try {
      const test = await generateTest(topic);
      setRecentTests(prev => [test, ...prev].slice(0, 5));
      setTopic('');
    } catch (err) {
      console.error('Failed to generate test:', err);
    }
  };

  const loadRecentTests = async () => {
    try {
      const tests = await listTests(5);
      setRecentTests(tests);
    } catch (err) {
      console.error('Failed to load recent tests:', err);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Generate Listening Tests
      </Typography>

      <Paper sx={{ p: 3, mb: 3 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Test Topic"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            disabled={isGenerating}
            placeholder="e.g., Environmental Conservation, Urban Development, Technology Impact"
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={isGenerating || !topic.trim()}
            sx={{ mr: 2 }}
          >
            {isGenerating ? (
              <>
                <CircularProgress size={24} sx={{ mr: 1 }} />
                Generating...
              </>
            ) : (
              'Generate Test'
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={loadRecentTests}
            disabled={isGenerating}
          >
            Load Recent Tests
          </Button>
        </form>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {generatedTest && (
          <Alert severity="success" sx={{ mt: 2 }}>
            Test generated successfully! ID: {generatedTest.id}
          </Alert>
        )}
      </Paper>

      {recentTests.length > 0 && (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Recent Tests
          </Typography>
          <List>
            {recentTests.map((test, index) => (
              <Box key={test.id}>
                {index > 0 && <Divider />}
                <ListItem>
                  <ListItemText
                    primary={test.title}
                    secondary={`Created: ${new Date(test.createdAt).toLocaleString()}`}
                  />
                </ListItem>
              </Box>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
}
