import { 
  Container, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Box,
  Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const faqs = [
    {
      question: "What is the IELTS test?",
      answer: `IELTS (International English Language Testing System) is the world's most popular English language test for higher education and global migration. It assesses all of your English language skills — reading, writing, listening and speaking. The test is designed to reflect how you'll use English at study, at work, and in your new life abroad.`
    },
    {
      question: "Who accepts the IELTS test?",
      answer: `IELTS is accepted by:
      • Over 11,000 universities and employers worldwide
      • All universities in Australia, New Zealand, and the UK
      • Most universities in Canada
      • Over 3,400 institutions in the USA
      • Immigration authorities in Australia, Canada, New Zealand, and the UK`
    },
    {
      question: "What are the different types of IELTS tests?",
      answer: `There are two types of IELTS tests:
      1. Academic - For people applying for higher education or professional registration
      2. General Training - For people migrating to Australia, Canada, New Zealand and the UK, or applying for secondary education, training programmes and work experience in an English-speaking environment`
    },
    {
      question: "What is the format of the IELTS Listening test?",
      answer: `The Listening test takes approximately 30 minutes and consists of 4 sections:
      • Section 1: A conversation between two people in an everyday social context
      • Section 2: A monologue in an everyday social context
      • Section 3: A conversation between up to four people in an educational or training context
      • Section 4: A monologue on an academic subject
      
      Each section contains 10 questions. The test is played ONCE only.`
    },
    {
      question: "What is the format of the IELTS Reading test?",
      answer: `The Reading test takes 60 minutes and contains 40 questions. The format differs for Academic and General Training:
      
      Academic:
      • 3 long texts from books, journals, magazines, and newspapers
      • Texts are authentic and academic in nature
      
      General Training:
      • Section 1: Contains 2-3 short texts from notices, advertisements, etc.
      • Section 2: Contains 2 texts about work-related issues
      • Section 3: Contains one longer text about a general topic`
    },
    {
      question: "What is the format of the IELTS Writing test?",
      answer: `The Writing test takes 60 minutes and has two tasks:
      
      Academic:
      • Task 1: Describe visual information (graph/table/chart/diagram) in 150+ words
      • Task 2: Write an essay responding to an argument in 250+ words
      
      General Training:
      • Task 1: Write a letter requesting information or explaining a situation
      • Task 2: Write an essay responding to a point of view or problem`
    },
    {
      question: "What is the format of the IELTS Speaking test?",
      answer: `The Speaking test takes 11-14 minutes and has three parts:
      • Part 1 (4-5 minutes): Introduction and general questions about yourself
      • Part 2 (3-4 minutes): Individual long turn - speak about a particular topic
      • Part 3 (4-5 minutes): Two-way discussion about more abstract ideas`
    },
    {
      question: "How is the IELTS test scored?",
      answer: `IELTS results are reported on a 9-band scale:
      • 9: Expert user
      • 8: Very good user
      • 7: Good user
      • 6: Competent user
      • 5: Modest user
      • 4: Limited user
      • 3: Extremely limited user
      • 2: Intermittent user
      • 1: Non-user
      • 0: Did not attempt the test
      
      You receive individual scores for each test section and an overall band score.`
    },
    {
      question: "How long are IELTS scores valid?",
      answer: "IELTS test results are valid for 2 years from the date of the test. This policy is in place because language ability can change significantly over time if not used regularly."
    },
    {
      question: "How can I prepare for the IELTS test?",
      answer: `Here are some effective ways to prepare:
      • Take practice tests under timed conditions
      • Improve your English through regular reading and listening
      • Practice speaking English regularly
      • Learn about the test format and question types
      • Use official IELTS preparation materials
      • Consider taking an IELTS preparation course
      • Practice writing essays and get feedback
      • Focus on time management skills`
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={0} sx={{ p: 4, bgcolor: 'background.default' }}>
        <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
          IELTS Test FAQ
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ mb: 4 }}>
          Find answers to commonly asked questions about the IELTS test, its format, scoring, and preparation strategies.
        </Typography>

        <Box sx={{ mb: 4 }}>
          {faqs.map((faq, index) => (
            <Accordion key={index} sx={{ mb: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  '&.Mui-expanded': {
                    backgroundColor: 'primary.light',
                    color: 'primary.contrastText',
                  }
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component="div"
                  sx={{
                    whiteSpace: 'pre-line',
                    '& ul': {
                      paddingLeft: 2,
                      marginTop: 1,
                      marginBottom: 1,
                    }
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4 }}>
          Note: This information is based on the official IELTS test format. Always check the official IELTS website for the most up-to-date information.
        </Typography>
      </Paper>
    </Container>
  );
};

export default FAQ;
