export const mockReadingTest = {
  id: '1',
  title: 'Academic Reading Test 1',
  sections: [
    {
      title: 'Section 1',
      passage: `The Impact of Climate Change on Marine Ecosystems

Climate change is having a profound effect on marine ecosystems worldwide. Rising ocean temperatures and increasing acidification are creating unprecedented challenges for marine life. Scientists have observed significant changes in various marine species' behavior, distribution, and survival rates.

One of the most visible impacts is on coral reefs, often called the "rainforests of the sea." These complex ecosystems provide habitat for countless marine species and are crucial for ocean biodiversity. However, rising water temperatures cause coral bleaching, a phenomenon where corals expel their symbiotic algae and turn white, often leading to their death. The Great Barrier Reef, the world's largest coral reef system, has experienced several major bleaching events in recent years.

Ocean acidification, caused by the absorption of excess carbon dioxide from the atmosphere, poses another significant threat. When seawater absorbs CO2, it becomes more acidic, making it difficult for marine organisms like corals, mollusks, and some plankton to build their calcium carbonate shells and skeletons. This has far-reaching implications for marine food webs and ecosystem stability.

Changes in ocean temperature are also affecting the distribution of marine species. Many fish populations are moving towards cooler waters near the poles, disrupting traditional fishing practices and local economies. Some species may face extinction if they cannot adapt quickly enough to these changing conditions.`,
      questions: [
        {
          id: '1',
          type: 'multiple-choice',
          question: 'What is described as the "rainforests of the sea"?',
          options: [
            'Marine ecosystems',
            'Coral reefs',
            'Ocean temperatures',
            'Fish populations'
          ],
          correctAnswer: 'Coral reefs'
        },
        {
          id: '2',
          type: 'true-false-ng',
          question: 'Ocean acidification makes it easier for marine organisms to build calcium carbonate shells.',
          correctAnswer: 'false'
        },
        {
          id: '3',
          type: 'fill-in-blank',
          question: 'Rising water temperatures cause coral ________, where corals expel their symbiotic algae.',
          correctAnswer: 'bleaching'
        }
      ]
    },
    {
      title: 'Section 2',
      passage: `The Evolution of Artificial Intelligence

The development of artificial intelligence (AI) has progressed rapidly in recent decades, transforming from a concept of science fiction into a reality that affects many aspects of our daily lives. The journey of AI began in the 1950s with simple programs that could solve mathematical problems, but today's AI systems can recognize speech, analyze complex data, and even create art.

Machine learning, a subset of AI, has been particularly revolutionary. Unlike traditional programming where rules are explicitly coded, machine learning algorithms can learn from experience. They analyze patterns in data and improve their performance over time without being specifically programmed for each task. This capability has led to breakthroughs in various fields, from medical diagnosis to autonomous vehicles.

Deep learning, an even more sophisticated form of machine learning, uses artificial neural networks inspired by the human brain. These networks can process vast amounts of data and identify patterns that would be impossible for humans to detect. This has enabled achievements like real-time language translation, facial recognition systems, and advanced game-playing AI that can beat human champions.

However, the rapid advancement of AI also raises important ethical considerations. Questions about privacy, job displacement, and the potential risks of increasingly autonomous systems are at the forefront of public discourse. As AI continues to evolve, society must carefully balance its benefits with potential drawbacks.`,
      questions: [
        {
          id: '4',
          type: 'multiple-choice',
          question: 'What is described as being inspired by the human brain?',
          options: [
            'Machine learning',
            'Traditional programming',
            'Artificial neural networks',
            'Mathematical problems'
          ],
          correctAnswer: 'Artificial neural networks'
        },
        {
          id: '5',
          type: 'true-false-ng',
          question: 'Machine learning algorithms must be specifically programmed for each task they perform.',
          correctAnswer: 'false'
        },
        {
          id: '6',
          type: 'fill-in-blank',
          question: 'The development of AI began in the ________ with simple programs that could solve mathematical problems.',
          correctAnswer: '1950s'
        }
      ]
    }
  ]
};
