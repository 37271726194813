import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const OPENAI_API_ENDPOINT = 'https://api.openai.com/v1';

class OpenAIService {
  constructor() {
    this.apiKey = import.meta.env.VITE_OPENAI_API_KEY;
  }

  async generateListeningContent(topic) {
    try {
      const response = await fetch(`${OPENAI_API_ENDPOINT}/chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey}`
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: [{
            role: 'system',
            content: `You are an IELTS listening test creator. Create a realistic IELTS listening test passage about ${topic}. 
            The passage should:
            1. Be suitable for a 2-3 minute audio recording
            2. Include natural pauses and transitions
            3. Follow IELTS test patterns (conversation for section 1-2, academic discussion/lecture for section 3-4)
            4. Include specific details that can be used for test questions
            5. Use appropriate vocabulary and complexity for IELTS
            Return only the passage text, no additional formatting or metadata.`
          }],
          temperature: 0.7
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate listening content');
      }

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      console.error('Error generating listening content:', error);
      throw error;
    }
  }

  async generateQuestions(passage) {
    try {
      const response = await fetch(`${OPENAI_API_ENDPOINT}/chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey}`
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: [{
            role: 'system',
            content: `You are an IELTS question creator. Create 3-4 questions based on the following passage. 
            Include a mix of:
            - Multiple choice questions
            - Fill in the blank questions
            - Matching questions
            Return the questions in JSON format following this structure:
            {
              "questions": [
                {
                  "id": "string",
                  "type": "multiple-choice | fill-in-blank | matching",
                  "text": "string",
                  "options": ["string"] (for multiple-choice),
                  "maxLength": number (for fill-in-blank),
                  "pairs": [{"left": "string", "right": "string"}] (for matching),
                  "correctAnswer": "string" or ["string"],
                  "hint": "string"
                }
              ]
            }`
          },
          {
            role: 'user',
            content: passage
          }],
          temperature: 0.7
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate questions');
      }

      const data = await response.json();
      return JSON.parse(data.choices[0].message.content);
    } catch (error) {
      console.error('Error generating questions:', error);
      throw error;
    }
  }

  async generateAudio(text) {
    try {
      // Generate the audio using OpenAI's API
      const response = await fetch(`${OPENAI_API_ENDPOINT}/audio/speech`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey}`
        },
        body: JSON.stringify({
          model: 'tts-1',
          input: text,
          voice: 'alloy',
          response_format: 'mp3'
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate audio');
      }

      // Get the audio blob
      const audioBlob = await response.blob();
      
      // Create a temporary URL for the blob
      const tempUrl = URL.createObjectURL(audioBlob);
      
      // Create an audio element to check if the audio is valid
      const audio = new Audio(tempUrl);
      
      return new Promise((resolve, reject) => {
        audio.onloadedmetadata = async () => {
          // Keep using the blob URL temporarily until Firebase Storage is set up
          resolve(tempUrl);
        };
        
        audio.onerror = () => {
          URL.revokeObjectURL(tempUrl);
          reject(new Error('Invalid audio data'));
        };
      });
    } catch (error) {
      console.error('Error generating audio:', error);
      throw error;
    }
  }

  async createListeningTest(topic) {
    try {
      // 1. Generate the passage text
      const passage = await this.generateListeningContent(topic);
      
      // 2. Generate questions based on the passage
      const { questions } = await this.generateQuestions(passage);
      
      // 3. Generate audio from the passage
      const audioUrl = await this.generateAudio(passage);
      
      // 4. Create the complete test object
      const test = {
        id: `test-${Date.now()}`,
        title: `IELTS Listening Practice Test: ${topic}`,
        description: topic,
        audioUrl,
        transcript: passage,
        duration: 180, // 3 minutes in seconds
        sections: [{
          id: 'section-1',
          title: `Section 1: ${topic}`,
          description: `A passage about ${topic}`,
          questions
        }]
      };
      
      return test;
    } catch (error) {
      console.error('Error creating listening test:', error);
      throw error;
    }
  }
}

export const openaiService = new OpenAIService();
