import { Container, Typography, Paper, Box } from '@mui/material';

function About() {
  return (
    <Container maxWidth="md">
      <Paper elevation={0} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          About IELTS Tutor
        </Typography>
        
        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            Our Mission
          </Typography>
          <Typography paragraph>
            At IELTS Tutor, we're dedicated to helping students achieve their desired IELTS scores through personalized, AI-powered learning experiences. Our platform combines cutting-edge technology with proven teaching methodologies to create an effective and engaging learning environment.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            What We Offer
          </Typography>
          <Typography paragraph>
            • Personalized learning paths adapted to your needs<br />
            • Comprehensive practice in all IELTS sections<br />
            • AI-powered feedback and scoring<br />
            • Real-time progress tracking<br />
            • Expert-designed practice materials
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            Our Approach
          </Typography>
          <Typography paragraph>
            We believe in a holistic approach to IELTS preparation. Our platform doesn't just help you practice; it helps you understand the examination patterns, develop effective strategies, and build the confidence you need to succeed.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            Why Choose Us
          </Typography>
          <Typography paragraph>
            • Advanced AI technology for personalized feedback<br />
            • Comprehensive coverage of all IELTS sections<br />
            • Flexible learning schedule<br />
            • Regular updates with new content<br />
            • Affordable pricing plans
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default About;
