import { Paper, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

function PassageDisplay({ passage, title }) {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography 
        component="div" 
        sx={{
          lineHeight: 1.8,
          fontSize: '1rem',
          '& p': {
            marginBottom: 2
          }
        }}
      >
        {passage.split('\n').map((paragraph, index) => (
          <p key={index}>{paragraph.trim()}</p>
        ))}
      </Typography>
    </Box>
  );
}

PassageDisplay.propTypes = {
  passage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PassageDisplay;
