import { Box, Typography } from '@mui/material';

function SpeakingPractice() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Speaking Practice
      </Typography>
      <Typography variant="body1">
        Speaking practice section is under development.
      </Typography>
    </Box>
  );
}

export default SpeakingPractice;
