import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  useTheme,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

function PlanCard({ 
  plan, 
  type, 
  handleStartTrial, 
  createOrder, 
  handlePaypalApprove, 
  onError,
  clientId 
}) {
  const theme = useTheme();
  const Icon = plan.icon;

  if (type === 'trial') {
    return (
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'translateY(-4px)',
          },
        }}
      >
        <CardHeader
          title={plan.title}
          titleTypographyProps={{ align: 'center' }}
          sx={{
            backgroundColor: 'primary.light',
            color: 'white',
          }}
        />
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Box sx={{ color: 'primary.main', mb: 2 }}>
              <Icon sx={{ fontSize: 40 }} />
            </Box>
            <Typography variant="h3" color="primary">
              Free
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {plan.duration}
            </Typography>
          </Box>
          <List sx={{ flexGrow: 1 }}>
            {plan.features.map((feature) => (
              <ListItem key={feature}>
                <ListItemIcon>
                  <CheckIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            ))}
          </List>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={handleStartTrial}
            sx={{
              mt: 2,
              py: 1.5,
              background: theme.palette.gradient.primary,
              '&:hover': {
                background: theme.palette.gradient.primary,
                opacity: 0.9,
              },
            }}
          >
            Start Free Trial
          </Button>
        </CardContent>
      </Card>
    );
  }

  const isLifetime = type === 'lifetime';

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        ...(isLifetime && {
          boxShadow: theme.shadows[10],
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: theme.shadows[15],
          },
        }),
        ...(!isLifetime && {
          '&:hover': {
            transform: 'translateY(-4px)',
          },
        }),
      }}
    >
      <CardHeader
        title={plan.title}
        titleTypographyProps={{ 
          align: 'center',
          ...(isLifetime && { fontWeight: 'bold' }),
        }}
        sx={{
          backgroundColor: isLifetime ? 'primary.main' : 'secondary.main',
          color: 'white',
          ...(isLifetime && { background: theme.palette.gradient.primary }),
        }}
      />
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Box sx={{ color: isLifetime ? 'primary.main' : 'secondary.main', mb: 2 }}>
            <Icon sx={{ fontSize: 40 }} />
          </Box>
          <Typography
            variant="h3"
            sx={isLifetime ? {
              background: theme.palette.gradient.primary,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 'bold',
            } : { color: 'secondary.main' }}
          >
            ${plan.price}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {plan.duration}
          </Typography>
          {isLifetime && (
            <Typography
              variant="subtitle2"
              sx={{
                color: 'success.main',
                mt: 1,
                fontWeight: 'bold',
              }}
            >
              {plan.savings}
            </Typography>
          )}
        </Box>
        <List sx={{ flexGrow: 1 }}>
          {plan.features.map((feature) => (
            <ListItem key={feature}>
              <ListItemIcon>
                <CheckIcon sx={{ color: isLifetime ? 'primary.main' : 'secondary.main' }} />
              </ListItemIcon>
              <ListItemText 
                primary={feature} 
                primaryTypographyProps={{
                  ...(isLifetime && { fontWeight: 500 }),
                }}
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: 2 }}>
          <PayPalScriptProvider options={{
            'client-id': clientId,
            currency: 'USD',
            intent: 'capture',
          }}>
            <PayPalButtons
              style={{
                layout: 'vertical',
                color: isLifetime ? 'gold' : 'blue',
                shape: 'rect',
                label: isLifetime ? 'pay' : 'subscribe',
              }}
              createOrder={(data, actions) => createOrder(data, actions, type, plan.price)}
              onApprove={(data, actions) => handlePaypalApprove(data, actions, type)}
              onError={onError}
            />
          </PayPalScriptProvider>
        </Box>
      </CardContent>
    </Card>
  );
}

export default PlanCard;
