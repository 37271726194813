import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  collection, 
  addDoc, 
  serverTimestamp, 
  getDoc,
  doc 
} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from './useAuth';
import {
  selectCurrentSession,
  selectCurrentAudio,
  startNewPracticeSession,
  updateAnswer,
  submitPracticeSession,
  setAudioDetails,
  updateAudioProgress
} from '../store/slices/listeningPracticeSlice';
import { questionBank, getRandomQuestions } from '../data/listeningQuestionBank';

const PRACTICE_DURATION = 1800; // 30 minutes in seconds
const QUESTIONS_PER_SECTION = 10;

const useListeningPractice = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const currentSession = useSelector(selectCurrentSession);
  const currentAudio = useSelector(selectCurrentAudio);
  const [practiceTest, setPracticeTest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);
  
  // Get subscription state from Redux
  const { isSubscribed } = useSelector(state => state.subscription);
  
  const { answers, isComplete, totalTime } = currentSession;

  // Initialize practice session with random questions
  useEffect(() => {
    if (!user) return;

    const initializeSession = async () => {
      try {
        setLoading(true);
        setError(null);

        // Generate random questions for each section
        const sections = getRandomQuestions(QUESTIONS_PER_SECTION);
        
        // Create practice test with random questions
        const test = {
          id: `practice-${Date.now()}`,
          title: 'IELTS Listening Practice',
          description: 'Practice test with randomly selected questions',
          sections: Object.values(sections)
        };

        setPracticeTest(test);
        dispatch(startNewPracticeSession());
        dispatch(setAudioDetails({
          url: sections.section1.audioUrl,
          duration: 0,
          isPlaying: false,
          currentTime: 0
        }));
        
        setLoading(false);
      } catch (err) {
        console.error('Error initializing practice:', err);
        setError('Failed to initialize practice. Please try again.');
        setLoading(false);
      }
    };

    initializeSession();
  }, [user, dispatch]);

  // Use subscription state from Redux
  useEffect(() => {
    setHasAccess(isSubscribed);
  }, [isSubscribed]);

  const questions = practiceTest ? practiceTest.sections.flatMap(section => section.questions) : [];

  const handleAnswerChange = useCallback((questionId, answer) => {
    if (!hasAccess) return;
    dispatch(updateAnswer({ questionId, answer }));
  }, [dispatch, hasAccess]);

  const handleTimeUp = useCallback(() => {
    if (!hasAccess) return;
    handleComplete();
  }, [hasAccess]);

  const handleComplete = useCallback(async () => {
    if (!user || !practiceTest || !hasAccess) return;

    try {
      dispatch(submitPracticeSession());

      // Calculate results
      const correctAnswers = questions.filter(q => 
        answers[q.id] === q.correctAnswer
      ).length;

      await addDoc(collection(db, 'practiceResults'), {
        type: 'listening',
        userId: user.uid,
        answers,
        totalQuestions: questions.length,
        correctAnswers,
        timeTaken: totalTime,
        sections: practiceTest.sections.map(section => ({
          id: section.id,
          title: section.title,
          correctAnswers: section.questions.filter(q => 
            answers[q.id] === q.correctAnswer
          ).length,
          totalQuestions: section.questions.length
        })),
        timestamp: serverTimestamp()
      });
    } catch (error) {
      console.error('Error saving practice results:', error);
      setError('Failed to save your results. Please try again.');
    }
  }, [dispatch, questions, answers, totalTime, user, practiceTest, hasAccess]);

  const handleAudioTimeUpdate = useCallback((time) => {
    if (!hasAccess) return;
    dispatch(updateAudioProgress(time));
    dispatch(setAudioDetails({
      ...currentAudio,
      currentTime: time
    }));
  }, [dispatch, currentAudio, hasAccess]);

  const isSessionComplete = useCallback(() => {
    return questions.every(q => answers[q.id]);
  }, [questions, answers]);

  const getProgress = useCallback(() => {
    if (!practiceTest) return null;

    const totalQuestions = questions.length;
    const answeredQuestions = Object.keys(answers).length;

    return {
      total: totalQuestions,
      answered: answeredQuestions,
      percentage: (answeredQuestions / totalQuestions) * 100,
      sections: practiceTest.sections.map(section => ({
        id: section.id,
        title: section.title,
        answered: section.questions.filter(q => answers[q.id]).length,
        total: section.questions.length,
        percentage: (section.questions.filter(q => answers[q.id]).length / section.questions.length) * 100
      }))
    };
  }, [questions, answers, practiceTest]);

  const getSectionProgress = useCallback((sectionId) => {
    if (!practiceTest) return null;

    const section = practiceTest.sections.find(s => s.id === sectionId);
    if (!section) return null;

    const answered = section.questions.filter(q => answers[q.id]).length;
    return {
      answered,
      total: section.questions.length,
      percentage: (answered / section.questions.length) * 100
    };
  }, [answers, practiceTest]);

  const isSectionComplete = useCallback((sectionId) => {
    if (!practiceTest) return false;

    const section = practiceTest.sections.find(s => s.id === sectionId);
    if (!section) return false;
    return section.questions.every(q => answers[q.id]);
  }, [answers, practiceTest]);

  return {
    // State
    currentSession,
    questions,
    currentAudio,
    answers,
    isComplete,
    totalTime,
    testData: practiceTest,
    loading,
    error,
    hasAccess,
    
    // Constants
    PRACTICE_DURATION,
    
    // Handlers
    handleAnswerChange,
    handleTimeUp,
    handleComplete,
    handleAudioTimeUpdate,
    
    // Utility functions
    isSessionComplete,
    getProgress,
    getSectionProgress,
    isSectionComplete
  };
};

export default useListeningPractice;
