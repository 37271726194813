import { Box, LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function ProgressBar({ total, completed }) {
  const progress = Math.min((completed / total) * 100, 100);

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="body2" color="text.secondary">
          Progress
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {completed}/{total} Questions Answered
        </Typography>
      </Box>
      <LinearProgress 
        variant="determinate" 
        value={progress}
        sx={{
          height: 8,
          borderRadius: 4,
          backgroundColor: 'action.hover',
          '& .MuiLinearProgress-bar': {
            borderRadius: 4,
            backgroundColor: progress === 100 ? 'success.main' : 'primary.main',
          },
        }}
      />
    </Box>
  );
}

ProgressBar.propTypes = {
  total: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
};

export default ProgressBar;
