import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import { 
  loginUser, 
  logoutUser, 
  setLoading, 
  setError,
  initializeAuth 
} from '../store/slices/auth';
import { loadSubscriptionData } from '../store/slices/subscriptionSlice';
import { doc, getDoc } from 'firebase/firestore';

const ADMIN_EMAIL = 'deonmfe@gmail.com';
const AUTH_TIMEOUT = 10000; // 10 seconds timeout

export const useAuth = () => {
  const dispatch = useDispatch();
  const { user, loading, error, initialized } = useSelector((state) => state.auth);

  useEffect(() => {
    let unsubscribe;
    let timeoutId;

    const initAuth = async () => {
      // Set up timeout to prevent infinite loading
      timeoutId = setTimeout(() => {
        if (!initialized) {
          dispatch(setError('Authentication timed out'));
          dispatch(initializeAuth());
        }
      }, AUTH_TIMEOUT);
      
      unsubscribe = onAuthStateChanged(auth, 
        async (firebaseUser) => {
          try {
            if (firebaseUser) {
              // Get user data from Firestore
              const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
              const isAdmin = firebaseUser.email === ADMIN_EMAIL;
              
              const userData = {
                uid: firebaseUser.uid,
                email: firebaseUser.email,
                emailVerified: firebaseUser.emailVerified,
                displayName: firebaseUser.displayName,
                photoURL: firebaseUser.photoURL,
                role: isAdmin ? 'admin' : (userDoc.exists() ? userDoc.data().role : 'user')
              };

              console.log('Auth state changed - User data:', userData);
              dispatch(loginUser(userData));
              dispatch(loadSubscriptionData(firebaseUser.uid));
            } else {
              console.log('Auth state changed - No user');
              dispatch(logoutUser());
            }
          } catch (error) {
            console.error('Error processing auth state change:', error);
            dispatch(setError('Error processing authentication state'));
            dispatch(logoutUser());
          } finally {
            clearTimeout(timeoutId);
            dispatch(initializeAuth());
          }
        },
        (error) => {
          console.error('Auth state change error:', error);
          dispatch(setError('Authentication state monitoring failed'));
          clearTimeout(timeoutId);
          dispatch(initializeAuth());
        }
      );
    };

    initAuth();

    // Cleanup subscription and timeout
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dispatch]);

  const isAdmin = user?.email === ADMIN_EMAIL;

  return {
    user,
    loading,
    error,
    isAuthenticated: !!user,
    isAdmin,
    initialized
  };
};
