import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/auth';
import practiceReducer from './slices/practiceSlice';
import subscriptionReducer from './slices/subscriptionSlice';
import listeningPracticeReducer from './slices/listeningPracticeSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    practice: practiceReducer,
    subscription: subscriptionReducer,
    listeningPractice: listeningPracticeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['auth/setLoading', 'auth/setError'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.error', 'meta.arg'],
        // Ignore these paths in the state
        ignoredPaths: [
          'auth.error',
          'subscription.error',
          'practice.error',
          'listeningPractice.error',
        ],
      },
    }),
  devTools: import.meta.env.DEV,
});

export default store;
