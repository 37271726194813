import { testPassage } from './mockListeningData';

// Question bank for each section
export const questionBank = {
  section1: {
    title: 'Section 1: Time Management Lecture',
    description: 'A lecture about effective time management strategies',
    // Use a static test audio file for now
    audioUrl: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav',
    transcript: testPassage,
    questions: [
      {
        id: 'q1_1',
        type: 'multiple-choice',
        text: 'What is the main topic of the lecture?',
        options: [
          'Time management',
          'Study techniques',
          'University life',
          'Work-life balance'
        ],
        correctAnswer: 'Time management',
        hint: 'Listen to the opening statement of the lecture.'
      },
      {
        id: 'q1_2',
        type: 'multiple-choice',
        text: 'What should students include in their weekly schedule?',
        options: [
          'Only academic commitments',
          'Only social activities',
          'All fixed commitments including breaks',
          'Only work hours'
        ],
        correctAnswer: 'All fixed commitments including breaks',
        hint: 'Pay attention to the first strategy mentioned.'
      },
      {
        id: 'q1_3',
        type: 'true-false',
        text: 'According to the lecture, the schedule should never be changed once created.',
        correctAnswer: 'false',
        hint: 'Listen to the final point about schedule flexibility.'
      },
      {
        id: 'q1_4',
        type: 'fill-in-blank',
        text: 'The speaker recommends using the "_______-important matrix" to prioritize tasks.',
        correctAnswer: 'urgent',
        hint: 'Listen for the tool mentioned for prioritizing tasks.'
      }
    ]
  }
};

// Function to get random questions for each section
export function getRandomQuestions(numQuestionsPerSection = 10) {
  const sections = {};
  
  Object.keys(questionBank).forEach(sectionKey => {
    const section = questionBank[sectionKey];
    const availableQuestions = [...section.questions];
    const selectedQuestions = [];

    // Select random questions
    for (let i = 0; i < Math.min(numQuestionsPerSection, availableQuestions.length); i++) {
      const randomIndex = Math.floor(Math.random() * availableQuestions.length);
      selectedQuestions.push(availableQuestions.splice(randomIndex, 1)[0]);
    }

    sections[sectionKey] = {
      ...section,
      questions: selectedQuestions
    };
  });

  return sections;
}
