import { Paper, Typography, Box, Grid, Divider, Alert } from '@mui/material';
import PropTypes from 'prop-types';

function ResultSummary({ feedback }) {
  return (
    <Paper sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Reading Practice Results
      </Typography>
      
      <Box sx={{ my: 4 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3, 
                textAlign: 'center',
                bgcolor: 'primary.main',
                color: 'primary.contrastText'
              }}
            >
              <Typography variant="h6" gutterBottom>
                Overall Score
              </Typography>
              <Typography variant="h2">
                {feedback.score}
              </Typography>
              <Typography variant="subtitle1">
                Band Score
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6" gutterBottom>
        Detailed Feedback
      </Typography>
      
      <Grid container spacing={3}>
        {feedback.detailedFeedback.map((category, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom color="primary">
                {category.category}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Score: {category.score}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {category.comments}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Question Analysis
        </Typography>
        {feedback.questionFeedback?.map((feedback, index) => (
          <Alert
            key={index}
            severity={feedback.correct ? 'success' : 'error'}
            sx={{ mb: 2 }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Question {index + 1}
            </Typography>
            <Typography variant="body2">
              {feedback.explanation}
            </Typography>
          </Alert>
        ))}
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Improvement Suggestions
        </Typography>
        <Typography variant="body1" paragraph>
          {feedback.feedback}
        </Typography>
      </Box>
    </Paper>
  );
}

ResultSummary.propTypes = {
  feedback: PropTypes.shape({
    score: PropTypes.number.isRequired,
    feedback: PropTypes.string.isRequired,
    detailedFeedback: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
        comments: PropTypes.string.isRequired,
      })
    ).isRequired,
    questionFeedback: PropTypes.arrayOf(
      PropTypes.shape({
        correct: PropTypes.bool.isRequired,
        explanation: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default ResultSummary;
