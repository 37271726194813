import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAuth } from './hooks/useAuth';
import Layout from './components/Layout';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import ListeningPractice from './pages/practice/ListeningPractice';
import ReadingPractice from './pages/practice/ReadingPractice';
import WritingPractice from './pages/practice/WritingPractice';
import SpeakingPractice from './pages/practice/SpeakingPractice';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile';
import About from './pages/About';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Pricing from './pages/Pricing';
import AdminDashboard from './pages/admin/AdminDashboard';
import FAQ from './pages/FAQ';

function ProtectedRoute({ children, isAllowed, redirectPath = '/' }) {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
}

function App() {
  const { isAuthenticated, isAdmin, loading, error, initialized } = useAuth();

  // Only show loading state if auth hasn't been initialized
  if (loading && !initialized) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '100vh',
          bgcolor: 'background.default',
          gap: 2
        }}
      >
        <CircularProgress />
        <Typography variant="body2" color="text.secondary">
          Initializing...
        </Typography>
      </Box>
    );
  }

  // Show error state if there's an auth error
  if (error) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '100vh',
          bgcolor: 'background.default',
          gap: 2
        }}
      >
        <Typography variant="h6" color="error">
          Authentication Error
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute isAllowed={isAuthenticated} redirectPath="/login">
              <Dashboard />
            </ProtectedRoute>
          } 
        />
        <Route path="/practice">
          <Route 
            path="listening" 
            element={
              <ProtectedRoute isAllowed={isAuthenticated} redirectPath="/login">
                <ListeningPractice />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="reading" 
            element={
              <ProtectedRoute isAllowed={isAuthenticated} redirectPath="/login">
                <ReadingPractice />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="writing" 
            element={
              <ProtectedRoute isAllowed={isAuthenticated} redirectPath="/login">
                <WritingPractice />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="speaking" 
            element={
              <ProtectedRoute isAllowed={isAuthenticated} redirectPath="/login">
                <SpeakingPractice />
              </ProtectedRoute>
            } 
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route 
          path="/profile" 
          element={
            <ProtectedRoute isAllowed={isAuthenticated} redirectPath="/login">
              <Profile />
            </ProtectedRoute>
          } 
        />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/faq" element={<FAQ />} />
        <Route 
          path="/admin" 
          element={
            <ProtectedRoute isAllowed={isAuthenticated && isAdmin} redirectPath="/">
              <AdminDashboard />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </Layout>
  );
}

export default App;
