import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { 
  Box, 
  Container, 
  Paper, 
  Typography,
  Button,
  Alert,
  Grid,
  useTheme,
  useMediaQuery,
  CircularProgress
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import AudioPlayer from '../../components/listening-practice/AudioPlayer';
import QuestionList from '../../components/listening-practice/QuestionList';
import Timer from '../../components/listening-practice/Timer';
import ProgressBar from '../../components/listening-practice/ProgressBar';
import ResultSummary from '../../components/listening-practice/ResultSummary';
import TranscriptDisplay from '../../components/listening-practice/TranscriptDisplay';
import DiscussionChatbot from '../../components/listening-practice/DiscussionChatbot';
import useListeningPractice from '../../hooks/useListeningPractice';
import { setTranscript } from '../../store/slices/listeningPracticeSlice';

function ListeningPractice() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();
  const [activeSection, setActiveSection] = useState(0);
  const [showInstructions, setShowInstructions] = useState(true);

  const {
    currentSession,
    questions,
    currentAudio,
    answers,
    isComplete,
    totalTime,
    PRACTICE_DURATION,
    handleAnswerChange,
    handleTimeUp,
    handleComplete,
    handleAudioTimeUpdate,
    testData,
    loading,
    error,
    hasAccess
  } = useListeningPractice();

  useEffect(() => {
    if (!user?.uid) {
      navigate('/login');
      return;
    }

    if (isComplete && testData) {
      dispatch(setTranscript(testData.sections[activeSection].transcript));
    }
  }, [user, navigate, isComplete, testData, dispatch, activeSection]);

  if (!user) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="warning">
          Please log in to access the listening practice section.
        </Alert>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Try Again
        </Button>
      </Container>
    );
  }

  if (!hasAccess) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Paper elevation={2} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            Premium Feature
          </Typography>
          <Typography variant="body1" paragraph>
            Subscribe to access practice tests and many more features.
          </Typography>
          <Button
            component={Link}
            to="/pricing"
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 2 }}
          >
            View Subscription Plans
          </Button>
        </Paper>
      </Container>
    );
  }

  if (isComplete && testData) {
    const sessionResults = {
      totalQuestions: questions.length,
      correctAnswers: Object.values(answers).filter((answer, index) => 
        answer === questions[index].correctAnswer
      ).length,
      totalTime
    };

    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 4, mb: 6 }}>
          <ResultSummary 
            questions={questions}
            answers={answers}
            totalTime={totalTime}
          />
          <TranscriptDisplay 
            transcript={testData.sections[activeSection].transcript}
            questions={questions}
          />
          <DiscussionChatbot 
            sessionResults={sessionResults}
            transcript={testData.sections[activeSection].transcript}
          />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              Start New Practice
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }

  if (showInstructions) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 4, mb: 6 }}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              IELTS Listening Practice Instructions
            </Typography>
            <Typography variant="body1" paragraph>
              This practice test consists of four sections, each focusing on different
              aspects of listening comprehension. You will hear each section only once.
            </Typography>
            <Box sx={{ my: 3 }}>
              <Typography variant="h6" gutterBottom>
                Important Points:
              </Typography>
              <Typography component="ul" sx={{ pl: 2 }}>
                <li>You have 30 minutes to complete all sections</li>
                <li>Each section contains different types of questions</li>
                <li>Use keyboard shortcuts for easier audio control</li>
                <li>Answer all questions before completing the test</li>
              </Typography>
            </Box>
            <Button 
              variant="contained" 
              color="primary"
              size="large"
              onClick={() => setShowInstructions(false)}
              sx={{ mt: 2 }}
            >
              Start Practice
            </Button>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 6 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h4" component="h1">
                {testData.sections[activeSection].title}
              </Typography>
              <Timer 
                duration={PRACTICE_DURATION}
                onTimeUp={handleTimeUp}
              />
            </Box>
            <ProgressBar 
              questions={questions}
              answers={answers}
              onQuestionSelect={(index) => setActiveSection(Math.floor(index / 10))}
            />
          </Grid>

          <Grid item xs={12}>
            <Paper 
              sx={{ 
                p: 3, 
                mb: 3,
                bgcolor: 'background.paper',
                boxShadow: 2,
              }}
            >
              <AudioPlayer 
                audioUrl={testData.sections[activeSection].audioUrl}
                onTimeUpdate={handleAudioTimeUpdate}
              />
            </Paper>
          </Grid>
          
          <Grid item xs={12}>
            <Paper 
              sx={{ 
                p: 3,
                bgcolor: 'background.paper',
                boxShadow: 2,
                minHeight: isMobile ? 'auto' : '50vh',
                overflow: 'auto'
              }}
            >
              <QuestionList
                questions={testData.sections[activeSection].questions}
                onAnswerChange={handleAnswerChange}
              />
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleComplete}
                  sx={{
                    minWidth: 120,
                    '&:disabled': {
                      bgcolor: 'action.disabledBackground',
                      color: 'action.disabled',
                    },
                  }}
                >
                  Submit Answers
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default ListeningPractice;
