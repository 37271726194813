import { Box, Typography, FormControl, RadioGroup, Radio, FormControlLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';

function QuestionList({ questions, onAnswerChange }) {
  const renderQuestion = (question) => {
    switch (question.type) {
      case 'multiple-choice':
        return (
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              value={question.answer || ''}
              onChange={(e) => onAnswerChange(question.id, e.target.value)}
              sx={{ '& .MuiRadio-root': { p: 1 } }}
            >
              {question.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={
                    <Radio 
                      sx={{
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={option}
                  sx={{ mb: 1 }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      case 'fill-in-blank':
        return (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={question.answer || ''}
            onChange={(e) => onAnswerChange(question.id, e.target.value)}
            placeholder="Type your answer"
            sx={{
              mt: 1,
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
          />
        );

      case 'true-false':
        return (
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              value={question.answer || ''}
              onChange={(e) => onAnswerChange(question.id, e.target.value)}
              sx={{ '& .MuiRadio-root': { p: 1 } }}
            >
              {['true', 'false'].map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={
                    <Radio 
                      sx={{
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={option.charAt(0).toUpperCase() + option.slice(1)}
                  sx={{ mb: 1 }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      default:
        return null;
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Questions
      </Typography>
      {questions.map((question, index) => (
        <Box 
          key={question.id} 
          sx={{ 
            mb: 4,
            p: 2,
            borderRadius: 1,
            bgcolor: 'background.paper',
            boxShadow: 1,
          }}
        >
          <Typography 
            variant="body1" 
            gutterBottom 
            sx={{ 
              fontWeight: 500,
              color: 'text.primary',
              mb: 2,
            }}
          >
            {index + 1}. {question.text}
          </Typography>
          {renderQuestion(question)}
          {question.note && (
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ mt: 1, fontStyle: 'italic' }}
            >
              {question.note}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
}

QuestionList.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['multiple-choice', 'fill-in-blank', 'true-false']).isRequired,
      text: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.string),
      answer: PropTypes.string,
      note: PropTypes.string,
    })
  ).isRequired,
  onAnswerChange: PropTypes.func.isRequired,
};

export default QuestionList;
