import { Box, Container, Typography, Alert, useTheme } from '@mui/material';

function PricingHeader({ status, showSuccess }) {
  const theme = useTheme();

  return (
    <>
      {/* Subscription Status Banner */}
      <Alert 
        severity={status.severity}
        sx={{ 
          borderRadius: 0,
          py: 1,
          display: 'flex',
          justifyContent: 'center',
          '& .MuiAlert-message': {
            width: '100%',
            textAlign: 'center',
          },
          '& .MuiAlert-icon': {
            position: 'relative',
            left: 24, // Offset to maintain visual balance with centered text
          },
        }}
      >
        <Typography variant="subtitle1">
          {status.message}
        </Typography>
      </Alert>

      {showSuccess && (
        <Alert 
          severity="success" 
          sx={{ 
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'center',
            '& .MuiAlert-message': {
              width: '100%',
              textAlign: 'center',
            },
            '& .MuiAlert-icon': {
              position: 'relative',
              left: 24,
            },
          }}
        >
          Thank you for subscribing! You now have full access to all features.
        </Alert>
      )}

      {/* Header Section */}
      <Box
        sx={{
          background: theme.palette.gradient.primary,
          pt: 8,
          pb: 12,
          color: 'white',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h2" gutterBottom>
            Choose Your Plan
          </Typography>
          <Typography variant="h5" sx={{ mb: 2, opacity: 0.9 }}>
            Start with a 7-day free trial, no credit card required
          </Typography>
        </Container>
      </Box>
    </>
  );
}

export default PricingHeader;
