import { createAsyncThunk } from '@reduxjs/toolkit';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../../firebase';
import { 
  loginUser, 
  logoutUser, 
  setLoading, 
  setError,
  clearError 
} from './authReducer';

export const initializeAuth = createAsyncThunk(
  'auth/initialize',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      // Auth state is handled by useAuth hook
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error.message));
      throw error;
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      
      const userData = {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        emailVerified: userCredential.user.emailVerified,
        displayName: userCredential.user.displayName,
        photoURL: userCredential.user.photoURL,
        role: userDoc.exists() ? userDoc.data().role : 'user'
      };

      dispatch(loginUser(userData));
      dispatch(clearError());
      return userData;
    } catch (error) {
      dispatch(setError(error.message));
      throw error;
    }
  }
);

export const signup = createAsyncThunk(
  'auth/signup',
  async ({ email, password }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create user document in Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email: userCredential.user.email,
        role: 'user',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      const userData = {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        emailVerified: userCredential.user.emailVerified,
        displayName: userCredential.user.displayName,
        photoURL: userCredential.user.photoURL,
        role: 'user'
      };

      dispatch(loginUser(userData));
      dispatch(clearError());
      return userData;
    } catch (error) {
      dispatch(setError(error.message));
      throw error;
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await signOut(auth);
      dispatch(logoutUser());
      dispatch(clearError());
    } catch (error) {
      dispatch(setError(error.message));
      throw error;
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (email, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await sendPasswordResetEmail(auth, email);
      dispatch(clearError());
    } catch (error) {
      dispatch(setError(error.message));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);
