import { 
  Box, 
  IconButton, 
  Slider, 
  Typography, 
  Stack, 
  CircularProgress, 
  Tooltip,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { 
  PlayArrow, 
  Pause, 
  Replay5, 
  Forward5,
  VolumeUp,
  Warning 
} from '@mui/icons-material';
import useAudioControl from '../../hooks/useAudioControl';
import useAudioKeyboardControls from '../../hooks/useAudioKeyboardControls';
import ControlsHelp from './ControlsHelp';

const AudioPlayer = ({ audioUrl }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    audioRef,
    isPlaying,
    duration,
    currentTime,
    isLoading,
    error,
    controls: {
      togglePlay,
      seekByPercentage,
      replay,
      forward
    },
    formatTime
  } = useAudioControl(audioUrl);

  const { keyboardShortcuts } = useAudioKeyboardControls({
    togglePlay,
    replay,
    forward
  });

  if (error) {
    return (
      <Box 
        sx={{ 
          width: '100%', 
          p: 2, 
          bgcolor: 'error.light', 
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
        role="alert"
      >
        <Warning color="error" />
        <Typography color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        width: '100%', 
        p: isMobile ? 1 : 2, 
        bgcolor: 'background.paper', 
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`
      }}
      role="region"
      aria-label="Audio player"
    >
      <audio
        ref={audioRef}
        src={audioUrl}
        preload="metadata"
      />
      
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Stack spacing={2}>
          <Stack 
            direction="column" 
            spacing={2} 
            sx={{ 
              bgcolor: 'background.default',
              borderRadius: 1,
              p: 1
            }}
          >
            {/* Playback Controls */}
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title="Rewind 5 seconds (←)" arrow>
                  <IconButton 
                    onClick={() => replay(5)} 
                    size="small"
                    aria-label="Rewind 5 seconds"
                  >
                    <Replay5 />
                  </IconButton>
                </Tooltip>

                <Tooltip title={isPlaying ? 'Pause (Space)' : 'Play (Space)'} arrow>
                  <IconButton 
                    onClick={togglePlay} 
                    size="large"
                    aria-label={isPlaying ? 'Pause' : 'Play'}
                  >
                    {isPlaying ? <Pause /> : <PlayArrow />}
                  </IconButton>
                </Tooltip>

                <Tooltip title="Forward 5 seconds (→)" arrow>
                  <IconButton 
                    onClick={() => forward(5)} 
                    size="small"
                    aria-label="Forward 5 seconds"
                  >
                    <Forward5 />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>

            {/* Progress Bar and Time */}
            <Stack spacing={1}>
              <Stack 
                direction="row" 
                spacing={1} 
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ minWidth: 45 }}
                  aria-label="Current time"
                >
                  {formatTime(currentTime)}
                </Typography>
                
                <Box sx={{ flexGrow: 1 }}>
                  <Tooltip title="Seek through audio" arrow>
                    <Slider
                      value={(currentTime / duration) * 100 || 0}
                      onChange={(_, value) => seekByPercentage(value)}
                      aria-label="Audio progress"
                      sx={{
                        '& .MuiSlider-thumb': {
                          width: 12,
                          height: 12,
                          '&:hover, &.Mui-focusVisible': {
                            boxShadow: `0px 0px 0px 8px ${theme.palette.primary.main}30`
                          }
                        }
                      }}
                    />
                  </Tooltip>
                </Box>
                
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ minWidth: 45 }}
                  aria-label="Total duration"
                >
                  {formatTime(duration)}
                </Typography>
              </Stack>

              {/* Volume and Controls Help */}
              <Stack 
                direction="row" 
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack 
                  direction="row" 
                  spacing={1} 
                  alignItems="center"
                >
                  <VolumeUp 
                    sx={{ 
                      color: 'text.secondary', 
                      fontSize: 20 
                    }} 
                  />
                  <Typography variant="body2" color="text.secondary">
                    100%
                  </Typography>
                </Stack>

                <ControlsHelp />
              </Stack>
            </Stack>
          </Stack>

          <Typography 
            variant="caption" 
            color="text.secondary" 
            align="center"
            component="div"
            role="note"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Use keyboard shortcuts for quick control - Click the help icon (?) to view all shortcuts
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default AudioPlayer;
