import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  useTheme,
} from '@mui/material';
import {
  Headphones as ListeningIcon,
  MenuBook as ReadingIcon,
  Edit as WritingIcon,
  RecordVoiceOver as SpeakingIcon,
} from '@mui/icons-material';

const practiceModules = [
  {
    title: 'Listening',
    description: 'Master listening comprehension with real IELTS-style audio tests',
    icon: <ListeningIcon sx={{ fontSize: 40, color: '#2962ff' }} />,
  },
  {
    title: 'Reading',
    description: 'Enhance reading skills with authentic IELTS passages',
    icon: <ReadingIcon sx={{ fontSize: 40, color: '#7c4dff' }} />,
  },
  {
    title: 'Writing',
    description: 'Perfect your writing with AI-powered feedback',
    icon: <WritingIcon sx={{ fontSize: 40, color: '#ff1744' }} />,
  },
  {
    title: 'Speaking',
    description: 'Improve pronunciation with real-time AI feedback',
    icon: <SpeakingIcon sx={{ fontSize: 40, color: '#00c853' }} />,
  },
];

function Home() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isSubscribed = useSelector((state) => state.subscription.isSubscribed);

  const handleGetStarted = () => {
    if (!isAuthenticated) {
      navigate('/login');
    } else if (!isSubscribed) {
      navigate('/pricing');
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <Box sx={{ bgcolor: '#faf6f1' }}>
      {/* Hero Section */}
      <Container maxWidth="lg">
        <Box
          sx={{
            pt: { xs: 8, md: 12 },
            pb: { xs: 8, md: 10 },
            textAlign: 'left',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.5rem', md: '3.75rem' },
              fontWeight: 800,
              color: '#1a1a1a',
              mb: 3,
              maxWidth: '800px',
              lineHeight: 1.2,
            }}
          >
            Master the skills you need today for IELTS success tomorrow
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mb: 4,
              color: '#666',
              maxWidth: '600px',
              lineHeight: 1.5,
              fontSize: { xs: '1.1rem', md: '1.3rem' },
            }}
          >
            With our AI-powered learning platform and unique teaching method, you'll have everything you need to achieve your target IELTS score.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={handleGetStarted}
            sx={{
              py: 2,
              px: 6,
              fontSize: '1.1rem',
              borderRadius: '8px',
              textTransform: 'none',
              backgroundColor: '#4c1d95',
              '&:hover': {
                backgroundColor: '#6d28d9',
              },
            }}
          >
            {!isAuthenticated ? 'Start Learning for Free' : !isSubscribed ? 'Upgrade Now' : 'Go to Dashboard'}
          </Button>
        </Box>

        {/* Practice Modules Section */}
        <Box sx={{ py: { xs: 6, md: 10 } }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              color: '#1a1a1a',
              mb: 6,
              textAlign: 'center',
            }}
          >
            Master all IELTS skills in one place
          </Typography>
          <Grid container spacing={4}>
            {practiceModules.map((module) => (
              <Grid item xs={12} sm={6} md={3} key={module.title}>
                <Card
                  sx={{
                    height: '100%',
                    boxShadow: 'none',
                    border: '1px solid #e5e7eb',
                    borderRadius: '12px',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                    },
                  }}
                >
                  <CardContent sx={{ p: 4 }}>
                    <Box sx={{ mb: 2 }}>{module.icon}</Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        mb: 1,
                        color: '#1a1a1a',
                      }}
                    >
                      {module.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#666',
                        lineHeight: 1.6,
                      }}
                    >
                      {module.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Call to Action */}
        <Box
          sx={{
            py: 8,
            textAlign: 'center',
            borderTop: '1px solid #e5e7eb',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '1.75rem', md: '2.25rem' },
              fontWeight: 700,
              color: '#1a1a1a',
              mb: 2,
            }}
          >
            Ready to achieve your target IELTS score?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 4,
              color: '#666',
              fontWeight: 'normal',
              fontSize: { xs: '1rem', md: '1.1rem' },
            }}
          >
            Join thousands of students who have improved their IELTS scores with our platform
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/pricing')}
            sx={{
              py: 2,
              px: 6,
              fontSize: '1.1rem',
              borderRadius: '8px',
              textTransform: 'none',
              backgroundColor: '#4c1d95',
              '&:hover': {
                backgroundColor: '#6d28d9',
              },
            }}
          >
            View Pricing Plans
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;
