import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { loginUser, logoutUser, setError } from '../store/slices/auth';
import { resetSubscription } from '../store/slices/subscriptionSlice';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export const handleAuthError = (error) => {
  switch (error.code) {
    case 'auth/invalid-email':
      return 'Invalid email address';
    case 'auth/user-disabled':
      return 'This account has been disabled';
    case 'auth/user-not-found':
      return 'No account found with this email';
    case 'auth/wrong-password':
      return 'Incorrect password';
    case 'auth/email-already-in-use':
      return 'An account already exists with this email';
    case 'auth/weak-password':
      return 'Password should be at least 6 characters';
    case 'auth/network-request-failed':
      return 'Network error. Please check your connection';
    case 'auth/too-many-requests':
      return 'Too many attempts. Please try again later';
    case 'auth/operation-not-allowed':
      return 'Operation not allowed';
    default:
      return error.message || 'An error occurred during authentication';
  }
};

export const handleLogout = () => async (dispatch) => {
  try {
    await signOut(auth);
    dispatch(logoutUser());
    dispatch(resetSubscription());
  } catch (error) {
    dispatch(setError(handleAuthError(error)));
    throw error;
  }
};

export const handleSignup = (userData) => async (dispatch) => {
  try {
    const { uid, email } = userData;
    await setDoc(doc(db, 'users', uid), {
      email,
      role: 'user',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
    dispatch(loginUser(userData));
  } catch (error) {
    dispatch(setError(handleAuthError(error)));
    throw error;
  }
};

export const handleLogin = (userData) => async (dispatch) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userData.uid));
    if (userDoc.exists()) {
      const userDataWithRole = {
        ...userData,
        role: userDoc.data().role || 'user'
      };
      dispatch(loginUser(userDataWithRole));
    } else {
      dispatch(loginUser(userData));
    }
  } catch (error) {
    dispatch(setError(handleAuthError(error)));
    throw error;
  }
};
