import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  CircularProgress,
  Alert,
  Grid,
} from '@mui/material';
import { startSession, submitAnswer } from '../../store/slices/practiceSlice';
import Timer from '../../components/reading-practice/Timer';
import PassageDisplay from '../../components/reading-practice/PassageDisplay';
import QuestionList from '../../components/reading-practice/QuestionList';
import ProgressBar from '../../components/reading-practice/ProgressBar';
import ResultSummary from '../../components/reading-practice/ResultSummary';
import DiscussionChatbot from '../../components/reading-practice/DiscussionChatbot';
import { mockReadingTest } from '../../data/mockReadingData';

function ReadingPractice() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentSession, loading, error } = useSelector((state) => state.practice);
  const { isSubscribed } = useSelector((state) => state.subscription);
  const { user } = useSelector((state) => state.auth);
  
  const [answers, setAnswers] = useState({});
  const [activeSection, setActiveSection] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(60 * 60); // 60 minutes
  const [showInstructions, setShowInstructions] = useState(true);

  useEffect(() => {
    if (!user?.uid) {
      navigate('/login');
      return;
    }

    if (!isSubscribed) {
      navigate('/pricing');
      return;
    }

    // Initialize practice session
    dispatch(startSession({
      type: 'reading',
      questions: mockReadingTest.sections.flatMap(section => section.questions),
    }));

    return () => {
      // Cleanup if needed
    };
  }, [dispatch, navigate, user, isSubscribed]);

  const handleAnswerChange = (questionId, value) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: value,
    }));
    dispatch(submitAnswer({ questionId, answer: value }));
  };

  const handleSubmit = () => {
    // Calculate score and generate feedback
    const feedback = {
      score: 7.0, // Mock score
      feedback: "Good attempt! You've shown strong comprehension skills but could improve on inference questions.",
      detailedFeedback: [
        {
          category: 'Reading Comprehension',
          score: 7.5,
          comments: 'Strong understanding of main ideas and supporting details.',
        },
        {
          category: 'Vocabulary Usage',
          score: 7.0,
          comments: 'Good grasp of academic vocabulary with some room for improvement.',
        },
        {
          category: 'Time Management',
          score: 6.5,
          comments: 'Consider allocating time more efficiently between passages.',
        }
      ],
      questionFeedback: Object.entries(answers).map(([id, answer]) => ({
        questionId: id,
        correct: Math.random() > 0.3, // Mock correctness
        explanation: 'Your answer demonstrates good understanding of the passage.',
      })),
    };

    // Update session with feedback
    dispatch(submitAnswer({ feedback }));
    setShowResults(true);
  };

  const handleTimeEnd = () => {
    handleSubmit();
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  if (showResults && currentSession?.feedback) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 4, mb: 6 }}>
          <ResultSummary feedback={currentSession.feedback} />
          <DiscussionChatbot feedback={currentSession.feedback} />
        </Box>
      </Container>
    );
  }

  if (showInstructions) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 4, mb: 6 }}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              IELTS Reading Practice Instructions
            </Typography>
            <Typography variant="body1" paragraph>
              This practice test consists of three passages with increasing difficulty,
              designed to test your reading comprehension skills. Each passage contains
              a set of questions that assess different aspects of your reading ability.
            </Typography>
            <Box sx={{ my: 3 }}>
              <Typography variant="h6" gutterBottom>
                Important Points:
              </Typography>
              <Typography component="ul" sx={{ pl: 2 }}>
                <li>You have 60 minutes to complete all three passages</li>
                <li>Each passage contains different types of questions (multiple choice, matching, completion)</li>
                <li>Read the instructions for each question type carefully</li>
                <li>Manage your time wisely - spend about 20 minutes per passage</li>
                <li>Answer all questions, even if you're not sure - there's no penalty for wrong answers</li>
              </Typography>
            </Box>
            <Button 
              variant="contained" 
              color="primary"
              size="large"
              onClick={() => setShowInstructions(false)}
              sx={{ mt: 2 }}
            >
              Start Practice
            </Button>
          </Paper>
        </Box>
      </Container>
    );
  }

  const currentSection = mockReadingTest.sections[activeSection];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 6 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h4" component="h1">
                IELTS Reading Practice
              </Typography>
              <Timer initialTime={timeRemaining} onTimeEnd={handleTimeEnd} />
            </Box>
            <ProgressBar 
              total={currentSection.questions.length} 
              completed={Object.keys(answers).filter(id => 
                currentSection.questions.some(q => q.id === id)
              ).length}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Paper 
              sx={{ 
                p: 3, 
                height: '70vh', 
                overflow: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 2,
              }}
            >
              <PassageDisplay 
                passage={currentSection.passage}
                title={currentSection.title}
              />
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Paper 
              sx={{ 
                p: 3, 
                height: '70vh', 
                overflow: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 2,
              }}
            >
              <QuestionList
                questions={currentSection.questions}
                answers={answers}
                onAnswerChange={handleAnswerChange}
              />
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={Object.keys(answers).length === 0}
                  sx={{
                    minWidth: 120,
                    '&:disabled': {
                      bgcolor: 'action.disabledBackground',
                      color: 'action.disabled',
                    },
                  }}
                >
                  Submit Answers
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default ReadingPractice;
