import { useMemo, useCallback } from 'react';

const useQuestionValidation = (questions, answers) => {
  // Calculate IELTS band score (approximate conversion)
  const calculateBandScore = useCallback((percentage) => {
    if (percentage >= 90) return 9.0;
    if (percentage >= 85) return 8.5;
    if (percentage >= 80) return 8.0;
    if (percentage >= 75) return 7.5;
    if (percentage >= 70) return 7.0;
    if (percentage >= 65) return 6.5;
    if (percentage >= 60) return 6.0;
    if (percentage >= 55) return 5.5;
    if (percentage >= 50) return 5.0;
    if (percentage >= 45) return 4.5;
    if (percentage >= 40) return 4.0;
    return 3.5;
  }, []);

  // Calculate scores and validation results
  const validationResults = useMemo(() => {
    return questions.map(question => {
      const userAnswer = answers[question.id];
      const isAnswered = Boolean(userAnswer);
      let isCorrect = false;
      let feedback = '';

      if (isAnswered) {
        switch (question.type) {
          case 'multiple_choice':
            isCorrect = userAnswer === question.correctAnswer;
            feedback = isCorrect 
              ? 'Correct!' 
              : `Incorrect. The correct answer is: ${question.correctAnswer}`;
            break;

          case 'fill_in_blank':
            // Case-insensitive comparison and trim whitespace
            isCorrect = userAnswer.trim().toLowerCase() === 
              question.correctAnswer.trim().toLowerCase();
            feedback = isCorrect
              ? 'Correct!'
              : `Incorrect. The correct answer is: ${question.correctAnswer}`;
            break;

          case 'matching':
            // For matching questions, compare arrays
            isCorrect = Array.isArray(userAnswer) &&
              Array.isArray(question.correctAnswer) &&
              userAnswer.length === question.correctAnswer.length &&
              userAnswer.every((answer, index) => 
                answer.trim().toLowerCase() === 
                question.correctAnswer[index].trim().toLowerCase()
              );
            feedback = isCorrect
              ? 'All matches are correct!'
              : 'Some matches are incorrect. Check your answers.';
            break;

          default:
            feedback = 'Unknown question type';
            break;
        }
      } else {
        feedback = 'Question not answered';
      }

      return {
        questionId: question.id,
        isAnswered,
        isCorrect,
        feedback,
        userAnswer,
        correctAnswer: question.correctAnswer
      };
    });
  }, [questions, answers]);

  // Calculate overall score and statistics
  const statistics = useMemo(() => {
    const total = questions.length;
    const answered = validationResults.filter(r => r.isAnswered).length;
    const correct = validationResults.filter(r => r.isCorrect).length;
    const score = total > 0 ? Math.round((correct / total) * 100) : 0;

    return {
      total,
      answered,
      correct,
      incorrect: answered - correct,
      unanswered: total - answered,
      score,
      isPerfect: score === 100,
      isFailing: score < 60,
      bandScore: calculateBandScore(score)
    };
  }, [questions.length, validationResults, calculateBandScore]);

  // Get feedback for a specific question
  const getQuestionFeedback = useCallback((questionId) => {
    return validationResults.find(r => r.questionId === questionId)?.feedback || '';
  }, [validationResults]);

  // Check if a specific answer is correct
  const isAnswerCorrect = useCallback((questionId) => {
    return validationResults.find(r => r.questionId === questionId)?.isCorrect || false;
  }, [validationResults]);

  // Get validation status for form display
  const getValidationStatus = useCallback((questionId) => {
    const result = validationResults.find(r => r.questionId === questionId);
    if (!result?.isAnswered) return 'unanswered';
    return result.isCorrect ? 'correct' : 'incorrect';
  }, [validationResults]);

  // Generate performance summary
  const getPerformanceSummary = useCallback(() => {
    const { score, bandScore } = statistics;
    
    if (score >= 90) {
      return 'Excellent! You\'ve demonstrated a superior understanding of the listening material.';
    } else if (score >= 80) {
      return 'Very good! You\'ve shown strong listening comprehension skills.';
    } else if (score >= 70) {
      return 'Good work! Your listening skills are developing well.';
    } else if (score >= 60) {
      return 'Fair effort. With more practice, you can improve your listening skills.';
    } else {
      return 'Keep practicing! Focus on understanding key details and main ideas.';
    }
  }, [statistics]);

  return {
    validationResults,
    statistics,
    getQuestionFeedback,
    isAnswerCorrect,
    getValidationStatus,
    getPerformanceSummary
  };
};

export default useQuestionValidation;
