import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: true, // Start with loading true to handle initial auth check
  error: null,
  initialized: false, // New flag to track if auth has been initialized
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.loading = false;
      state.error = null;
      state.initialized = true;
    },
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.loading = false;
      state.error = null;
      state.initialized = true;
    },
    setLoading: (state, action) => {
      // Only set loading if auth hasn't been initialized yet
      if (!state.initialized || action.payload === false) {
        state.loading = action.payload;
      }
      if (action.payload === true) {
        state.error = null;
      }
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.initialized = true;
    },
    clearError: (state) => {
      state.error = null;
    },
    updateUser: (state, action) => {
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload,
        };
      }
    },
    resetAuthState: () => ({
      ...initialState,
      loading: true,
      initialized: false,
    }),
    initializeAuth: (state) => {
      state.initialized = true;
      if (state.loading && !state.user) {
        state.loading = false;
      }
    },
  },
});

// Export actions
export const {
  loginUser,
  logoutUser,
  setLoading,
  setError,
  clearError,
  updateUser,
  resetAuthState,
  initializeAuth,
} = authSlice.actions;

// Export reducer
export default authSlice.reducer;
