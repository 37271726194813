import { Box, Container, Grid, Link, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const theme = useTheme();
  const navigate = useNavigate();

  const footerLinks = [
    {
      title: 'Company',
      links: [
        { text: 'About', path: '/about' },
        { text: 'Contact', path: '/contact' },
        { text: 'Pricing', path: '/pricing' },
        { text: 'FAQ', path: '/faq' },
      ]
    },
    {
      title: 'Legal',
      links: [
        { text: 'Terms of Service', path: '/terms' },
        { text: 'Privacy Policy', path: '/privacy' },
      ]
    },
  ];

  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        px: 2,
        mt: 'auto',
        backgroundColor: theme.palette.grey[100],
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          {footerLinks.map((section) => (
            <Grid item xs={12} sm={6} md={3} key={section.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {section.title}
              </Typography>
              <Box>
                {section.links.map((link) => (
                  <Link
                    key={link.text}
                    component="button"
                    variant="body2"
                    onClick={() => navigate(link.path)}
                    sx={{
                      display: 'block',
                      mb: 1,
                      color: 'text.secondary',
                      textAlign: 'left',
                      textDecoration: 'none',
                      '&:hover': {
                        color: 'primary.main',
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {link.text}
                  </Link>
                ))}
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              IELTS Tutor
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Master the skills you need today for IELTS success tomorrow
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 4 }}
        >
          © {new Date().getFullYear()} IELTS Tutor. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
