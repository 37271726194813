import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Alert,
  Typography,
} from '@mui/material';
import { startTrial, startSubscription, completeSubscription, failSubscription } from '../store/slices/subscriptionSlice';
import PricingHeader from '../components/pricing/PricingHeader';
import PlanCard from '../components/pricing/PlanCard';
import BenefitsSection from '../components/pricing/BenefitsSection';
import FAQSection from '../components/FAQSection';
import { subscriptionPlans } from '../components/pricing/subscriptionPlans';

function Pricing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSubscribed, paymentStatus, subscriptionType } = useSelector((state) => state.subscription);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (isSubscribed) {
      setShowSuccess(true);
      const timer = setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSubscribed]);

  const handleStartTrial = () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    dispatch(startTrial());
  };

  const handlePaypalApprove = async (data, actions, planType) => {
    try {
      const details = await actions.order.capture();
      dispatch(completeSubscription({
        type: planType,
        transactionId: details.id,
      }));
    } catch (error) {
      dispatch(failSubscription());
    }
  };

  const createOrder = (data, actions, planType, amount) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    
    dispatch(startSubscription({ type: planType }));

    return actions.order.create({
      purchase_units: [
        {
          description: `IELTS Tutor ${planType === 'monthly' ? 'Monthly' : 'Lifetime'} Access`,
          amount: {
            value: amount,
            currency_code: 'USD',
          },
        },
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING',
      },
    });
  };

  const getSubscriptionStatus = () => {
    if (!isAuthenticated) {
      return {
        message: 'Sign in to access premium features',
        severity: 'info'
      };
    }

    if (isSubscribed) {
      if (subscriptionType === 'trial') {
        return {
          message: 'You are currently on a free trial',
          severity: 'info'
        };
      }
      return {
        message: `You have ${subscriptionType === 'lifetime' ? 'lifetime' : 'monthly'} access to all features`,
        severity: 'success'
      };
    }

    return {
      message: 'Choose a plan to access premium features',
      severity: 'warning'
    };
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <PricingHeader 
        status={getSubscriptionStatus()} 
        showSuccess={showSuccess} 
      />

      {/* Plans Section */}
      <Container maxWidth="lg" sx={{ mt: -8, position: 'relative', zIndex: 1 }}>
        {paymentStatus === 'failed' && (
          <Alert severity="error" sx={{ mb: 4 }}>
            Payment failed. Please try again.
          </Alert>
        )}

        {paymentStatus === 'pending' && (
          <Alert severity="info" sx={{ mb: 4 }}>
            Processing your payment...
          </Alert>
        )}

        <Grid container spacing={4} alignItems="stretch">
          {/* Trial Plan */}
          <Grid item xs={12} md={4}>
            <PlanCard
              plan={subscriptionPlans.trial}
              type="trial"
              handleStartTrial={handleStartTrial}
            />
          </Grid>

          {/* Monthly Plan */}
          <Grid item xs={12} md={4}>
            <PlanCard
              plan={subscriptionPlans.monthly}
              type="monthly"
              createOrder={createOrder}
              handlePaypalApprove={handlePaypalApprove}
              onError={() => dispatch(failSubscription())}
              clientId={import.meta.env.VITE_PAYPAL_CLIENT_ID}
            />
          </Grid>

          {/* Lifetime Plan */}
          <Grid item xs={12} md={4}>
            <PlanCard
              plan={subscriptionPlans.lifetime}
              type="lifetime"
              createOrder={createOrder}
              handlePaypalApprove={handlePaypalApprove}
              onError={() => dispatch(failSubscription())}
              clientId={import.meta.env.VITE_PAYPAL_CLIENT_ID}
            />
          </Grid>
        </Grid>

        {/* Benefits Section */}
        <BenefitsSection />

        {/* FAQ Section */}
        <FAQSection />

        {/* Payment Security Notice */}
        <Box sx={{ mt: 6, mb: 8, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            Secure payment processed by PayPal. Your information is protected by 256-bit SSL encryption.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Pricing;
