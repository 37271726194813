// Simple logger utility for debugging
const formatValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    try {
      return JSON.stringify(value, null, 2);
    } catch (err) {
      return '[Circular]';
    }
  }
  return value;
};

const formatArgs = (...args) => args.map(formatValue).join(' ');

const logger = {
  info: (...args) => {
    console.log('[INFO]', formatArgs(...args));
  },
  error: (...args) => {
    console.error('[ERROR]', formatArgs(...args));
  },
  warn: (...args) => {
    console.warn('[WARN]', formatArgs(...args));
  },
  debug: (...args) => {
    console.log('[DEBUG]', formatArgs(...args));
  },
  // Log with component name
  component: (componentName) => ({
    info: (...args) => {
      console.log(`[${componentName}]`, formatArgs(...args));
    },
    error: (...args) => {
      console.error(`[${componentName}]`, formatArgs(...args));
    },
    warn: (...args) => {
      console.warn(`[${componentName}]`, formatArgs(...args));
    },
    debug: (...args) => {
      console.log(`[${componentName}]`, formatArgs(...args));
    },
  }),
};

export default logger;
